/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import { Col, Form, Input, Row, Select, Tour } from 'antd'
import React, { useEffect } from 'react'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'

import { translate } from '~/utils/locale'

import ContributorsTaskTable from '../Modal/Contributor/Table'

import { useTaskTour } from '~/hooks/Tour/useTaskTour'
import { useTour } from '~/hooks/Tour/useTour'
import TeamsTaskTable from '../Modal/Teams/Table'

interface ResponsibleCreateTaskProps {
  form: any
}

const ResponsibleCreateTask: React.FC<ResponsibleCreateTaskProps> = ({
  form,
}) => {
  const { contributorData, teamData, setType, type } = useTaskCreate()

  const {
    openResponsibleTask,
    setOpenResponsibleTask,
    createResponsibleSteps,
  } = useTaskTour()

  const { setIsTour } = useTour()

  function onClose() {
    setIsTour(false)
    setOpenResponsibleTask(false)
  }

  const { Option } = Select

  const handleChange = (value: string) => {
    setType(value)
  }

  useEffect(() => {
    if (type === 'collaborator' && contributorData?.id) {
      form.setFieldsValue({ id: contributorData?.name })
    } else if (type === 'team' && teamData?.id) {
      form.setFieldsValue({ id: teamData?.name })
    } else {
      form.setFieldsValue({ id: undefined })
    }
  }, [contributorData, teamData, type, form])

  return (
    <>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item label="Tarefa será realizada por" name="type">
            <Select
              placeholder="Informe por quem a tarefa será realizada"
              onChange={handleChange}
              style={{ width: '100%' }}
            >
              <Option value="collaborator">Colaborador</Option>
              <Option value="team">Equipes</Option>
              <Option value="any">Nenhum responsável</Option>
            </Select>
          </Form.Item>
        </Col>

        {type === 'collaborator' ? (
          <Col span={12}>
            <Form.Item
              label={translate('TasksCreateResponsible.contributorText')}
              name="id"
            >
              <Input placeholder="Selecione abaixo o colaborador responsável" />
            </Form.Item>
          </Col>
        ) : (
          ''
        )}

        {type === 'team' ? (
          <Col span={12}>
            <Form.Item
              label={translate('TasksCreateResponsible.teamText')}
              name="id"
            >
              <Input placeholder="Selecione abaixo a equipe responsável" />
            </Form.Item>
          </Col>
        ) : (
          ''
        )}
      </Row>

      <Row>
        <Col span={24}>
          {type === 'collaborator' ? <ContributorsTaskTable form={form} /> : ''}
          {type === 'team' ? <TeamsTaskTable /> : ''}
        </Col>
      </Row>

      <Tour
        open={openResponsibleTask}
        onClose={onClose}
        steps={createResponsibleSteps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
    </>
  )
}

export default ResponsibleCreateTask
