import React, { useState } from 'react'
import { Button, Modal, Space, Tooltip, Typography, message } from 'antd'
import {
  WhatsAppOutlined,
  MailOutlined,
  CopyOutlined,
  ShareAltOutlined,
} from '@ant-design/icons'
import { FaTelegramPlane } from 'react-icons/fa'
import { translate } from '~/utils/locale'

interface ShareButtonProps {
  link: string
  modalText?: string
}

const ShareButton: React.FC<ShareButtonProps> = ({ link, modalText }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const openModal = (): void => setIsModalOpen(true)

  const closeModal = (): void => setIsModalOpen(false)

  const copyToClipboard = (): void => {
    navigator.clipboard.writeText(link)
    message.success(translate('MiscShareButton.messageSuccess'))
  }

  return (
    <div>
      <Tooltip title={translate('MiscShareButton.tooltipTitle')}>
        <Button
          ghost
          type="primary"
          shape="circle"
          icon={<ShareAltOutlined />}
          onClick={openModal}
        />
      </Tooltip>

      <Modal
        title={translate('MiscShareButton.modalTitle')}
        open={isModalOpen}
        onCancel={closeModal}
        footer={null}
      >
        {modalText && <Typography.Text>{modalText}</Typography.Text>}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: 20,
          }}
        >
          <Tooltip title={translate('MiscShareButton.whatsapp')}>
            <Space direction="vertical" align="center">
              <Button
                ghost
                type="primary"
                shape="circle"
                icon={<WhatsAppOutlined />}
                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                  link,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              />
            </Space>
          </Tooltip>

          <Tooltip title={translate('MiscShareButton.telegram')}>
            <Space direction="vertical" align="center">
              <Button
                ghost
                type="primary"
                shape="circle"
                icon={<FaTelegramPlane />}
                href={`https://telegram.me/share/url?url=${encodeURIComponent(
                  link,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              />
            </Space>
          </Tooltip>

          <Tooltip title={translate('MiscShareButton.email')}>
            <Space direction="vertical" align="center">
              <Button
                ghost
                type="primary"
                shape="circle"
                icon={<MailOutlined />}
                href={`mailto:?subject=Confira este link&body=${encodeURIComponent(
                  link,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              />
            </Space>
          </Tooltip>

          <Tooltip title={translate('MiscShareButton.copyText')}>
            <Space direction="vertical" align="center">
              <Button
                ghost
                type="primary"
                shape="circle"
                icon={<CopyOutlined />}
                onClick={copyToClipboard}
              />
            </Space>
          </Tooltip>
        </div>
      </Modal>
    </div>
  )
}

export default ShareButton
