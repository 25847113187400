export default {
  pt: {
    DashboardChartsTasksByCustomerAndValuePieChart: {
      filterText: 'Dados filtrados entre',
      cardTitle: 'Número de Tarefas e Valor médio por Cliente',
      noResultText: 'Não existem tarefas nas datas selecionadas no filtro',
      radioTotal: 'Total de Tarefas',
      radioAverageValue: 'Valor Médio',
      radioTotalValue: 'Valor Total',
    },
  },
  en: {
    DashboardChartsTasksByCustomerAndValuePieChart: {
      filterText: 'Data filtered between',
      cardTitle: 'Number of Tasks and Average Value per Client',
      noResultText: 'There are no tasks on the dates selected in the filter',
      radioTotal: 'Total Tasks',
      radioAverageValue: 'Average Value',
      radioTotalValue: 'Total Value',
    },
  },
  es: {
    DashboardChartsTasksByCustomerAndValuePieChart: {
      filterText: 'Datos filtrados entre',
      cardTitle: 'Número de tareas y valor medio por cliente',
      noResultText: 'No hay tareas en las fechas seleccionadas en el filtro',
      radioTotal: 'Tareas totales',
      radioAverageValue: 'Valor promedio',
      radioTotalValue: 'Valor Total',
    },
  },
}
