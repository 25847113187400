/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { Card, Col, Modal, Result, Typography } from 'antd'
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts'
import { useDashboardTasks } from '~/hooks/Dashboard/useDashboardTasks'
import ModalTasksTable from '~/components/DataManipulation/Dashboard/Tasks/Tables/ModalTable'
import { translate } from '~/utils/locale'
import dayjs from 'dayjs'

interface TasksByTypePieChartProps {
  filterInfoVisibility?: boolean
}

const TasksByTypePieChart: React.FC<TasksByTypePieChartProps> = ({
  filterInfoVisibility = false,
}) => {
  const { data } = useDashboardTasks()
  const [tasksData, setTasksData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const totalTasks = data.TasksByTypePieChart.reduce(
    (total, task) => total + task.value,
    0,
  )

  const stringToColor = (str: string) => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      color += ('00' + value.toString(16)).substr(-2)
    }
    return color
  }

  const handleClick = (data: any) => {
    setTasksData(data.tasks)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Col>
      {filterInfoVisibility && data.dateInfo && (
        <Typography.Title level={5}>
          {translate('DashboardChartsTasksByTypePieChart.filterText')}{' '}
          {dayjs(data?.dateInfo?.fromDate).format('DD/MM/YYYY')} -{' '}
          {dayjs(data?.dateInfo?.toDate).format('DD/MM/YYYY')}
        </Typography.Title>
      )}
      <Card
        title={
          <div style={{ textAlign: 'center' }}>
            {translate('DashboardChartsTasksByTypePieChart.cardTitle')}
          </div>
        }
        style={{
          width: '100%',
          height: '100%',
          maxHeight: '500px',
          boxShadow: 'none',
        }}
      >
        {data?.TasksByTypePieChart.length === 0 ? (
          <Result
            title={translate('DashboardChartsTasksByTypePieChart.noResultText')}
          />
        ) : (
          <>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  dataKey="value"
                  isAnimationActive={true}
                  animationDuration={750}
                  animationEasing="linear"
                  data={data?.TasksByTypePieChart}
                  cx="50%"
                  cy="50%"
                  outerRadius="80%"
                  innerRadius="50%"
                  fill="#8884d8"
                  label
                  onClick={handleClick}
                >
                  {data?.TasksByTypePieChart.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={stringToColor(entry.name)} // Use a cor gerada pela função hash
                      style={{ cursor: 'pointer' }}
                    />
                  ))}
                </Pie>
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fill="rgba(0, 0, 0, 0.85)"
                  fontSize="2em"
                  fontWeight="bold"
                >
                  {totalTasks}{' '}
                  {translate('DashboardChartsTasksByTypePieChart.tasksText')}
                </text>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>

            <Modal
              width="80vw"
              open={isModalOpen}
              onCancel={closeModal}
              footer={null}
            >
              <ModalTasksTable tasks={tasksData} />
            </Modal>
          </>
        )}
      </Card>
    </Col>
  )
}

export default TasksByTypePieChart
