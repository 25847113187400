/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import { FiLogIn, FiMail, FiLock, FiChevronRight } from 'react-icons/fi'

import { message } from 'antd'
import { decode } from 'base-64'
import { Container, Content, AnimationContainer, Background } from './styles'

import Input from '~/components/Login/Input'
import Button from '~/components/Login/Button'

import { useAuth } from '~/hooks/Auth/AuthContext'

import getValidationErrors from '~/utils/getValidationErrors'

import Logo from '../../../assets/logo_icon.svg'

import LogoGestor from '~/assets/logo_full.svg'
import { translate } from '~/utils/locale'
import api from '~/services/api'

interface SignInFormData {
  email: string
  password?: string
}

const SignIn: React.FC = () => {
  const [loading, setLoading] = useState(false)

  const typeAuth = localStorage.getItem('typeAuth')
  const userEmail: any = localStorage.getItem('userEmail')

  const decodeUserEmail = decode(userEmail)

  const formRef = useRef<FormHandles>(null)

  const history = useHistory()
  const { signIn, token, updateCompanie } = useAuth()

  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem('typeAuth')
      localStorage.removeItem('userEmail')
    }, 30000)
  }, [])

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        setLoading(true)

        formRef.current?.setErrors({})

        if (typeAuth !== 'panel') {
          const schema = Yup.object().shape({
            email: Yup.string().required(translate('SignIn.emailYup.required')),
            password: Yup.string().required(
              translate('SignIn.passwordYup.required'),
            ),
          })

          await schema.validate(data, {
            abortEarly: false,
          })
        }

        await signIn({
          email: data.email,
          password: data.password,
        })

        const company = await api.get('/users/companies', {
          headers: {
            Authorization: `Bearer ${token}`, // Adiciona o token no cabeçalho
          },
        })

        const companyId = company.data[0].company.id

        updateCompanie(companyId, { last_access: new Date() })

        history.push('/dashboard')
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
        }

        message.error(translate('SignIn.errorMessage'))
      } finally {
        setLoading(false)
      }
    },
    [signIn, history, typeAuth, token, updateCompanie],
  )

  return (
    <Container>
      <Helmet>
        <title>{translate('SignIn.helmetTitle')}</title>
      </Helmet>

      <Background>
        <img src={Logo} alt="Logo go.on" width="50px" />

        <h1>Gestão de Equipe Externa.</h1>

        <div className="lg-footer">
          <hr color="#3C8ACB" />
          <h4>Faça login na nossa plataforma</h4>
          <FiChevronRight size="20px" />
        </div>
      </Background>

      <Content>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <img src={LogoGestor} alt="Logo Gestor" />

            <h1>{translate('SignIn.title')}</h1>
            <p>{translate('SignIn.subTitle')}</p>

            {typeAuth !== 'panel' ? (
              <Input
                name="email"
                icon={FiMail}
                placeholder={translate('SignIn.emailPlaceholder')}
              />
            ) : (
              <Input
                name="email"
                icon={FiMail}
                placeholder={translate('SignIn.emailPlaceholder')}
                value={typeAuth === 'panel' ? decodeUserEmail : ''}
                disabled
              />
            )}

            {typeAuth !== 'panel' ? (
              <>
                <Input
                  name="password"
                  icon={FiLock}
                  isPassword
                  type="password"
                  placeholder={translate('SignIn.passwordPlaceholder')}
                />

                <Link to="forgot-password">
                  <FiLock />
                  {translate('SignIn.forgotPasswordText')}
                </Link>
              </>
            ) : (
              ''
            )}

            <Button loading={loading} type="submit">
              {translate('SignIn.buttonText')}
            </Button>
          </Form>

          <Link to="/signup">
            <FiLogIn />
            {translate('SignIn.createAccountText')}
          </Link>
        </AnimationContainer>
      </Content>
      {/* <Background /> */}
    </Container>
  )
}

export default SignIn
