/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingOutlined } from '@ant-design/icons'
import { Col, Modal, Row, Tabs, Tag, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { LoadingIndicator } from '~/pages/Contributor/ShowContributor/styles'
import { translate } from '~/utils/locale'
import { ModalContainer } from './styles'
import {
  FiCalendar,
  FiFile,
  FiFileText,
  FiTarget,
  FiUsers,
} from 'react-icons/fi'

import Text from 'antd/lib/typography/Text'
import { format } from 'date-fns'

interface ITaskInfoModal {
  selectedTask: any
  isModalVisible: boolean
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const TaskInfoModal: React.FC<ITaskInfoModal> = ({
  selectedTask,
  isModalVisible,
  setIsModalVisible,
}) => {
  const { isLoading, maintenance } = useShowMaintenance()

  if (isLoading || !maintenance) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const getStatusInfo = (status: string | undefined) => {
    switch (status) {
      case 'received':
        return (
          <Tag color="yellow">
            {translate('MaintenancePlanningTableInfoModal.statusReceived')}
          </Tag>
        )
      case 'viewed':
        return (
          <Tag color="green">
            {translate('MaintenancePlanningTableInfoModal.statusViewed')}
          </Tag>
        )
      case 'en-route':
        return (
          <Tag color="green">
            {translate('MaintenancePlanningTableInfoModal.statusEnRoute')}
          </Tag>
        )
      case 'working':
        return (
          <Tag color="green">
            {translate('MaintenancePlanningTableInfoModal.statusWorking')}
          </Tag>
        )
      case 'resume-working':
        return (
          <Tag color="green">
            {translate('MaintenancePlanningTableInfoModal.statusResumeWorking')}
          </Tag>
        )
      case 'break':
        return (
          <Tag color="red">
            {translate('MaintenancePlanningTableInfoModal.statusBreak')}
          </Tag>
        )
      case 'canceled':
        return (
          <Tag color="gray">
            {translate('MaintenancePlanningTableInfoModal.statusCanceled')}
          </Tag>
        )
      case 'finished':
        return (
          <Tag color="geekblue">
            {translate('MaintenancePlanningTableInfoModal.statusFinished')}
          </Tag>
        )
    }
  }

  return (
    <Modal
      open={isModalVisible}
      title={translate('MaintenancePlanningTableInfoModal.modalTitle')}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
      width="700px"
    >
      <Tabs>
        <Tabs.TabPane key="tasks" tab="Tarefa">
          {selectedTask ? (
            <ModalContainer>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div className="title">
                      <FiFile />
                      <Text strong>
                        {translate(
                          'MaintenancePlanningTableInfoModal.taskIdText',
                        )}
                      </Text>
                    </div>
                    {selectedTask?.activityDetails?.title ? (
                      <Tooltip
                        title={translate(
                          'MaintenancePlanningTableInfoModal.tooltipText',
                        )}
                      >
                        <Typography.Link
                          copyable={{ text: selectedTask.tasks[0].taskId }}
                          href={`/tarefas/visualizar/${selectedTask.tasks[0].taskId}`}
                          target="_blank"
                        >
                          {selectedTask.tasks[0].taskId.substring(0, 8)}
                        </Typography.Link>
                      </Tooltip>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate('TasksTabsDetails.noToText')}
                      </Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div className="title">
                      <FiTarget />
                      <Text strong>
                        {translate(
                          'MaintenancePlanningTableInfoModal.statusText',
                        )}
                      </Text>
                    </div>
                    {selectedTask.tasks[0].status ? (
                      getStatusInfo(selectedTask.tasks[0].status)
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate(
                          'MaintenancePlanningTableInfoModal.statusUndefined',
                        )}
                      </Text>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <div className="text">
                    <div className="title">
                      <FiCalendar />
                      <Text strong>
                        {translate(
                          'MaintenancePlanningTableInfoModal.taskDateText',
                        )}
                      </Text>
                    </div>
                    {selectedTask.tasks[0].created ? (
                      <Text style={{ fontSize: 16 }}>
                        {dayjs(selectedTask.tasks[0].created)
                          .add(3, 'hour')
                          .format('DD/MM/YYYY')}
                      </Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate(
                          'MaintenancePlanningTableInfoModal.noDateText',
                        )}
                      </Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div className="title">
                      <FiFileText />
                      <Text strong>
                        {translate(
                          'MaintenancePlanningTableInfoModal.planText',
                        )}
                      </Text>
                    </div>
                    {selectedTask.tasks[0].plan ? (
                      <Text style={{ fontSize: 16 }}>
                        {selectedTask.tasks[0].plan}
                      </Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate(
                          'MaintenancePlanningTableInfoModal.noPlanText',
                        )}
                      </Text>
                    )}
                  </div>
                </Col>
              </Row>
            </ModalContainer>
          ) : (
            ''
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          key="details"
          tab={translate('MaintenanceSystemTab.Details')}
        >
          {selectedTask ? (
            <ModalContainer>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div className="title">
                      <FiFile />
                      <Text strong>
                        {translate('MaintenanceSystemTab.ActivityTitle')}
                      </Text>
                    </div>
                    {selectedTask?.activityDetails?.title ? (
                      <Text style={{ fontSize: 16 }}>
                        {selectedTask?.activityDetails?.title}
                      </Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate('TasksTabsDetails.noToText')}
                      </Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div className="title">
                      <FiTarget />
                      <Text strong>
                        {translate('MaintenanceSystemTab.DataSource')}
                      </Text>
                    </div>
                    {selectedTask?.activityDetails?.data_font ? (
                      <Text style={{ fontSize: 16 }}>
                        {selectedTask?.activityDetails?.data_font}
                      </Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate('TasksTabsDetails.noToText')}
                      </Text>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div className="title">
                      <FiUsers />
                      <Text strong>
                        {translate('MaintenanceSystemTab.Competence')}
                      </Text>
                    </div>
                    {selectedTask?.activityDetails?.competence ? (
                      <Text style={{ fontSize: 16 }}>
                        {selectedTask?.activityDetails?.competence}
                      </Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate('TasksTabsDetails.noToText')}
                      </Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div className="title">
                      <FiUsers />
                      <Text strong>
                        {translate('MaintenanceSystemTab.Responsibles')}
                      </Text>
                    </div>
                    {selectedTask?.activityDetails?.responsible ? (
                      <Text style={{ fontSize: 16 }}>
                        {selectedTask?.activityDetails?.responsible}
                      </Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate(
                          'TasksTabsDetails.selectedTask.activityDetailsnoToText',
                        )}
                      </Text>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={12}>
                  <div className="text">
                    <div className="title">
                      <FiCalendar />
                      <Text strong>
                        {translate('MaintenanceSystemTab.Periodicity')}
                      </Text>
                    </div>
                    {selectedTask.activityDetails?.periodicity ? (
                      <>
                        {selectedTask.activityDetails.periodicity?.type ===
                        'day' ? (
                          <Text style={{ fontSize: 16 }}>
                            {translate('MaintenanceSystemTab.Text')}{' '}
                            {selectedTask.activityDetails.periodicity.interval}{' '}
                            {translate('MaintenanceSystemTab.Days')}
                          </Text>
                        ) : (
                          ''
                        )}

                        {selectedTask.activityDetails.periodicity?.type ===
                        'sem' ? (
                          <Text style={{ fontSize: 16 }}>
                            {translate('MaintenanceSystemTab.Text')}{' '}
                            {selectedTask.activityDetails.periodicity.interval}{' '}
                            {translate('MaintenanceSystemTab.Weeks')}
                          </Text>
                        ) : (
                          ''
                        )}

                        {selectedTask.activityDetails.periodicity?.type ===
                        'men' ? (
                          <Text style={{ fontSize: 16 }}>
                            {translate('MaintenanceSystemTab.Text')}{' '}
                            {selectedTask.activityDetails.periodicity.interval}{' '}
                            {translate('MaintenanceSystemTab.MonthsPlural')}
                          </Text>
                        ) : (
                          ''
                        )}

                        {selectedTask.activityDetails.periodicity?.type ===
                        'anu' ? (
                          <Text style={{ fontSize: 16 }}>
                            {translate('MaintenanceSystemTab.Text')}{' '}
                            {selectedTask.activityDetails.periodicity.interval}{' '}
                            {translate('MaintenanceSystemTab.Years')}
                          </Text>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <Text>{translate('TasksTabsDetails.noToText')}</Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="text">
                    <div className="title">
                      <FiCalendar />
                      <Text strong>
                        {translate('MaintenanceActivity.InitialDate')}
                      </Text>
                    </div>

                    {selectedTask.activityDetails?.initialDate
                      ? format(
                          new Date(selectedTask.activityDetails?.initialDate),
                          'dd/MM/yyyy',
                        )
                      : 'Nenhuma data informada'}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <div className="text">
                    <div className="title">
                      <FiFile />
                      <Text strong>
                        {translate('MaintenanceSystemTab.Activities')}
                      </Text>
                    </div>
                    {selectedTask.activityDetails?.title ? (
                      <Text style={{ fontSize: 16 }}>
                        {selectedTask.activityDetails.activity}
                      </Text>
                    ) : (
                      <Text style={{ fontSize: 16 }}>
                        {translate('TasksTabsDetails.noToText')}
                      </Text>
                    )}
                  </div>
                </Col>
              </Row>
            </ModalContainer>
          ) : (
            ''
          )}
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  )
}

export default TaskInfoModal
