import styled from 'styled-components'

interface TasksCardsProps {
  backgroundColor: string
}

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 380px 1fr;
  gap: 1rem;
  align-items: stretch;
  grid-auto-rows: 1fr;

  h4,
  h1 {
    margin: 0;
  }

  :where(.css-dev-only-do-not-override-xu9wm8).ant-rate {
    font-size: 0;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column; /* Alinha o conteúdo na vertical */
  justify-content: space-between; /* Espaça o conteúdo uniformemente */
  height: 100%; /* Garante que o card ocupe toda a altura disponível */
  border: 1px solid #dbdbdb;
  border-radius: 5px;

  .header {
    padding: 0.5rem;
    border-bottom: 1px solid #dbdbdb;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      background-color: #252525;
      color: white;
      font-size: 18px;
    }
  }

  h3 {
    margin: 0;
    color: #202020;
  }

  .content {
    flex-grow: 1;
    padding: 0.5rem;
  }

  .totalTasks {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #252525;
    padding: 0.5rem;
    border-radius: 8px;
    color: white;
  }
`
export const StatusTasks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.6rem;

  margin-top: 0.5rem;
`
export const StatusTasksContainer = styled.div<TasksCardsProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #f3f3f3;
  padding: 0.4rem;
  border-radius: 8px;

  .text {
    color: #474747;
  }

  .tag {
    background-color: ${(p) => p.backgroundColor};
    width: 35px;
    border-radius: 8px;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const TicketsContainer = styled.div`
  display: grid;
  padding: 0.5rem;
  gap: 0.8rem;
  grid-template-columns: 140px 1fr;
  height: 80%;

  .card {
    background: #252525;

    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding-left: 1rem;

    .icon {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      background-color: var(--quartenary-color);
      color: white;
      font-size: 18px;
    }

    p,
    h2 {
      color: white;
    }

    h2 {
      font-size: 28px;
      margin: 0;
      font-weight: bold;
    }
  }
`
export const StatusTicketContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.4rem;
`

export const ContributorsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.4rem;
  padding: 0.5rem;
`

export const RatesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.4rem;
  padding: 0 0.5rem 0.5rem;
`

export const RatesCard = styled.div`
  background: #252525;
  height: 100%;
  border-radius: 10px;
  padding: 0.4rem;

  .rates {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 0.4rem;
  }

  h4,
  p {
    color: white;
  }
`
