/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { Card, Col, Modal, Radio, Result, Typography } from 'antd'
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts'
import { useDashboardTasks } from '~/hooks/Dashboard/useDashboardTasks'
import ModalTasksTable from '~/components/DataManipulation/Dashboard/Tasks/Tables/ModalTable'
import { translate } from '~/utils/locale'
import dayjs from 'dayjs'

interface TasksByCustomerAndValuePieChartProps {
  filterInfoVisibility?: boolean
}

const TasksByCustomerAndValuePieChart: React.FC<
  TasksByCustomerAndValuePieChartProps
> = ({ filterInfoVisibility = false }) => {
  const { data } = useDashboardTasks()
  const [tasksData, setTasksData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [sortBy, setSortBy] = useState('totalTarefas')

  const sortedData = [...data?.CustomersTasks].sort(
    (a: any, b: any) => b[sortBy] - a[sortBy],
  )

  const stringToColor = (str: string) => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      color += ('00' + value.toString(16)).substr(-2)
    }
    return color
  }

  const handleClick = (data: any) => {
    setTasksData(data.tasks)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Col>
      {filterInfoVisibility && data?.dateInfo && (
        <Typography.Title level={5}>
          {translate(
            'DashboardChartsTasksByCustomerAndValuePieChart.filterText',
          )}{' '}
          {dayjs(data?.dateInfo?.fromDate).format('DD/MM/YYYY')} -{' '}
          {dayjs(data?.dateInfo?.toDate).format('DD/MM/YYYY')}
        </Typography.Title>
      )}
      <Card
        title={
          <div style={{ textAlign: 'center' }}>
            {translate(
              'DashboardChartsTasksByCustomerAndValuePieChart.cardTitle',
            )}
          </div>
        }
        style={{
          width: '100%',
          height: '100%',
          maxHeight: '500px',
          boxShadow: 'none',
        }}
      >
        {sortedData.length === 0 ? (
          <Result
            title={translate(
              'DashboardChartsTasksByCustomerAndValuePieChart.noResultText',
            )}
          />
        ) : (
          <>
            <Radio.Group
              onChange={(e) => setSortBy(e.target.value)}
              value={sortBy}
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '20px',
              }}
            >
              <Radio value={'totalTarefas'}>
                {translate(
                  'DashboardChartsTasksByCustomerAndValuePieChart.radioTotal',
                )}
              </Radio>
              <Radio value={'totalMedia'}>
                {translate(
                  'DashboardChartsTasksByCustomerAndValuePieChart.radioAverageValue',
                )}
              </Radio>
              <Radio value={'valorTotal'}>
                {translate(
                  'DashboardChartsTasksByCustomerAndValuePieChart.radioTotalValue',
                )}
              </Radio>
            </Radio.Group>

            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  dataKey={sortBy}
                  isAnimationActive={true}
                  animationDuration={750}
                  animationEasing="linear"
                  data={sortedData}
                  cx="50%"
                  cy="50%"
                  outerRadius="80%"
                  fill="#8884d8"
                  label={({ [sortBy]: value }) =>
                    sortBy === 'totalTarefas'
                      ? value
                      : `${value.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}`
                  }
                  onClick={handleClick}
                >
                  {sortedData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={stringToColor(entry.nome)}
                      style={{ cursor: 'pointer' }}
                    />
                  ))}
                </Pie>
                <Tooltip
                  formatter={(value, name, props) => {
                    const { payload } = props
                    const formattedValue =
                      sortBy === 'totalTarefas'
                        ? value
                        : value.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })
                    return [formattedValue, payload.nome]
                  }}
                />
              </PieChart>
            </ResponsiveContainer>

            <Modal
              width="80vw"
              open={isModalOpen}
              onCancel={closeModal}
              footer={null}
            >
              <ModalTasksTable tasks={tasksData} />
            </Modal>
          </>
        )}
      </Card>
    </Col>
  )
}

export default TasksByCustomerAndValuePieChart
