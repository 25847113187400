export default {
  pt: {
    DashboardChartsTasksTotalLineChart: {
      filterText: 'Dados filtrados entre',
      cardTitle: 'Total de Tarefas nos Últimos dias',
      noResultText: 'Não existem tarefas nas datas selecionadas no filtro',
    },
  },
  en: {
    DashboardChartsTasksTotalLineChart: {
      filterText: 'Data filtered between',
      cardTitle: 'Total Tasks in the Last Days',
      noResultText: 'There are no tasks on the dates selected in the filter',
    },
  },
  es: {
    DashboardChartsTasksTotalLineChart: {
      filterText: 'Datos filtrados entre',
      cardTitle: 'Tareas totales en los últimos días',
      noResultText: 'No hay tareas en las fechas seleccionadas en el filtro',
    },
  },
}
