import styled from 'styled-components'

export const ModalContainer = styled.div`
  .content-row {
    flex: 1;
  }

  .title {
    display: flex;
    align-items: center;
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    padding-bottom: 23px;
    font-size: 16px;

    svg {
      margin-right: 5px;
    }
  }
`
