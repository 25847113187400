import styled from 'styled-components'

export const Container = styled.div``

export const ContainerCharts = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1.3rem;
  gap: 1rem;
`
export const ContainerMap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 2.5rem;
  gap: 1rem;
`
