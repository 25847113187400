/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { useTaskBreak } from '~/hooks/Tasks/Kanban/useTaskBreak'
import { useTaskCanceled } from '~/hooks/Tasks/Kanban/useTaskCanceled'
import { useTaskEnRoute } from '~/hooks/Tasks/Kanban/useTaskEnRoute'
import { useTaskFinished } from '~/hooks/Tasks/Kanban/useTaskFinished'
import { useTaskReceived } from '~/hooks/Tasks/Kanban/useTaskReceived'
import { useTaskWorking } from '~/hooks/Tasks/Kanban/useTaskWorking'
import { LoadingIndicator } from '~/pages/Tasks/ShowTasks/styles'
import { translate } from '~/utils/locale'

import CardsKanban from '../Cards'

import { Container, Boards, Board, Wrapper } from './styles'
import { useTaskViewed } from '~/hooks/Tasks/Kanban/useTaskViewed'
import { useTaskCheckIn } from '~/hooks/Tasks/Kanban/useTaskCheckIn'

const ListKanban: React.FC = () => {
  const { taskReceived, isLoading, loadDataReceived, tasksReceivedTotal } =
    useTaskReceived()
  const {
    taskViewed,
    isLoading: isLoadingViewed,
    loadDataViewed,
    tasksViewedTotal,
  } = useTaskViewed()
  const {
    taskCheckIn,
    isLoading: isLoadingCheckIn,
    loadDataCheckIn,
    tasksCheckInTotal,
  } = useTaskCheckIn()
  const {
    taskEnRoute,
    tasksEnRouteTotal,
    loadDataEnRoute,
    isLoading: isLoadEnRoute,
  } = useTaskEnRoute()
  const {
    taskWorking,
    tasksWorkingTotal,
    loadDataWorking,
    isLoading: isLoadWork,
  } = useTaskWorking()
  const {
    taskBreak,
    tasksBreakTotal,
    loadDataBreak,
    isLoading: isLoadBreak,
  } = useTaskBreak()
  const {
    taskCanceled,
    tasksCanceledTotal,
    loadDataCanceled,
    isLoading: isLoadCancel,
  } = useTaskCanceled()
  const {
    taskFinished,
    tasksFinishedTotal,
    loadDataFinished,
    isLoading: isLoadFinish,
  } = useTaskFinished()

  if (
    isLoading ||
    !taskReceived ||
    isLoadBreak ||
    !taskBreak ||
    isLoadEnRoute ||
    !taskEnRoute ||
    isLoadWork ||
    !taskWorking ||
    isLoadCancel ||
    !taskCanceled ||
    isLoadFinish ||
    !taskFinished ||
    isLoadingViewed ||
    !taskViewed ||
    isLoadingCheckIn ||
    !taskCheckIn
  ) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const isColumns = JSON.parse(localStorage.getItem('kanbanConfigurations')!)
  const isCode = isColumns?.data_pattern?.includes('code')

  const size = isColumns?.data_pattern?.length

  return (
    <Container>
      <Boards>
        <Wrapper>
          <div>
            <div className="title" id="received">
              <div className="principal">
                {isCode && size === 1 ? '' : <div className="circle" />}
                <h4>{translate('TasksOptionsKanbanList.receivedText')}</h4>
              </div>
              <span>
                {tasksReceivedTotal}{' '}
                {translate('TasksOptionsKanbanList.tasksText')}
              </span>
            </div>
          </div>
          <InfiniteScroll
            dataLength={taskReceived.length}
            hasMore={taskReceived.length !== tasksReceivedTotal}
            next={loadDataReceived}
            loader={<LoadingOutlined />}
            scrollableTarget="scrollableDiv"
            height={'70vh'}
            className="teste"
          >
            <Board id="scrollableDiv" isCode={isCode && size === 1}>
              <div className="cards" id="teste">
                {taskReceived?.map((taskStatus) => (
                  <CardsKanban
                    taskStatusData={taskStatus}
                    key={taskStatus._id}
                  />
                ))}
              </div>
            </Board>
          </InfiniteScroll>
        </Wrapper>

        <Wrapper>
          <div>
            <div className="title" id="viewed">
              <div className="principal">
                {isCode && size === 1 ? '' : <div className="circle" />}
                <h4>{translate('TasksOptionsKanbanList.viewedText')}</h4>
              </div>
              <span>
                {tasksViewedTotal}{' '}
                {translate('TasksOptionsKanbanList.tasksText')}
              </span>
            </div>
          </div>
          <InfiniteScroll
            dataLength={taskViewed.length}
            hasMore={taskViewed.length !== tasksViewedTotal}
            next={loadDataViewed}
            loader={<LoadingOutlined />}
            scrollableTarget="scrollableDiv"
            height={'70vh'}
            className="teste"
          >
            <Board id="scrollableDiv" isCode={isCode && size === 1}>
              <div className="cards">
                {taskViewed?.map((taskStatus) => (
                  <CardsKanban
                    taskStatusData={taskStatus}
                    key={taskStatus._id}
                  />
                ))}
              </div>
            </Board>
          </InfiniteScroll>
        </Wrapper>

        <Wrapper>
          <div>
            <div className="title" id="accept">
              <div className="principal">
                {isCode && size === 1 ? '' : <div className="circle" />}
                <h4>{translate('TasksOptionsKanbanList.acceptedText')}</h4>
              </div>
              <span>
                {tasksCheckInTotal}{' '}
                {translate('TasksOptionsKanbanList.tasksText')}
              </span>
            </div>
          </div>
          <InfiniteScroll
            dataLength={taskCheckIn.length}
            hasMore={taskCheckIn.length !== tasksCheckInTotal}
            next={loadDataCheckIn}
            loader={<LoadingOutlined />}
            scrollableTarget="scrollableDiv"
            height={'70vh'}
            className="teste"
          >
            <Board id="scrollableDiv" isCode={isCode && size === 1}>
              <div className="cards">
                {taskCheckIn?.map((taskStatus) => (
                  <CardsKanban
                    taskStatusData={taskStatus}
                    key={taskStatus._id}
                  />
                ))}
              </div>
            </Board>
          </InfiniteScroll>
        </Wrapper>

        <Wrapper>
          <div>
            <div className="title" id="enroute">
              <div className="principal">
                {isCode && size === 1 ? '' : <div className="circle" />}
                <h4>{translate('TasksOptionsKanbanList.enRouteText')}</h4>
              </div>
              <span>
                {tasksEnRouteTotal}{' '}
                {translate('TasksOptionsKanbanList.tasksText')}
              </span>
            </div>
          </div>

          <InfiniteScroll
            dataLength={taskEnRoute.length}
            hasMore={taskEnRoute.length !== tasksEnRouteTotal}
            next={loadDataEnRoute}
            loader={<LoadingOutlined />}
            scrollableTarget="scrollableDiv"
            height={'70vh'}
          >
            <Board isCode={isCode && size === 1}>
              <div className="cards">
                {taskEnRoute?.map((taskStatus) => (
                  <CardsKanban
                    taskStatusData={taskStatus}
                    key={taskStatus._id}
                  />
                ))}
              </div>
            </Board>
          </InfiniteScroll>
        </Wrapper>

        <Wrapper>
          <div>
            <div className="title" id="working">
              <div className="principal">
                {isCode && size === 1 ? '' : <div className="circle" />}
                <h4>{translate('TasksOptionsKanbanList.workingText')}</h4>
              </div>
              <span>
                {tasksWorkingTotal}{' '}
                {translate('TasksOptionsKanbanList.tasksText')}
              </span>
            </div>
          </div>

          <InfiniteScroll
            dataLength={taskWorking.length}
            hasMore={taskWorking.length !== tasksWorkingTotal}
            next={loadDataWorking}
            loader={<LoadingOutlined />}
            scrollableTarget="scrollableDiv"
            height={'70vh'}
          >
            <Board isCode={isCode && size === 1}>
              <div className="cards">
                {taskWorking?.map((taskStatus) => (
                  <CardsKanban
                    taskStatusData={taskStatus}
                    key={taskStatus._id}
                  />
                ))}
              </div>
            </Board>
          </InfiniteScroll>
        </Wrapper>

        {isColumns?.columns ? (
          <Wrapper>
            <div>
              <div className="title" id="finished">
                <div className="principal">
                  {isCode && size === 1 ? '' : <div className="circle" />}
                  <h4>{translate('TasksOptionsKanbanList.finishedText')}</h4>
                </div>
                <span>
                  {tasksFinishedTotal}{' '}
                  {translate('TasksOptionsKanbanList.tasksText')}
                </span>
              </div>
            </div>

            <InfiniteScroll
              dataLength={taskFinished.length}
              hasMore={taskFinished.length !== tasksFinishedTotal}
              next={loadDataFinished}
              loader={<LoadingOutlined />}
              scrollableTarget="scrollableDiv"
              height={'70vh'}
            >
              <Board isCode={isCode && size === 1}>
                <div className="cards">
                  {taskFinished?.map((taskStatus) => (
                    <CardsKanban
                      taskStatusData={taskStatus}
                      key={taskStatus._id}
                    />
                  ))}
                </div>
              </Board>
            </InfiniteScroll>
          </Wrapper>
        ) : (
          ''
        )}

        {!isColumns?.columns ? (
          <>
            <Wrapper>
              <div>
                <div className="title" id="break">
                  <div className="principal">
                    {isCode && size === 1 ? '' : <div className="circle" />}
                    <h4>{translate('TasksOptionsKanbanList.breakText')}</h4>
                  </div>
                  <span>
                    {tasksBreakTotal}{' '}
                    {translate('TasksOptionsKanbanList.tasksText')}
                  </span>
                </div>
              </div>

              <InfiniteScroll
                dataLength={taskBreak.length}
                hasMore={taskBreak.length !== tasksBreakTotal}
                next={loadDataBreak}
                loader={<LoadingOutlined />}
                scrollableTarget="scrollableDiv"
                height={'70vh'}
              >
                <Board isCode={isCode && size === 1}>
                  <div className="cards">
                    {taskBreak?.map((taskStatus) => (
                      <CardsKanban
                        taskStatusData={taskStatus}
                        key={taskStatus._id}
                      />
                    ))}
                  </div>
                </Board>
              </InfiniteScroll>
            </Wrapper>
            <Wrapper>
              <div>
                <div className="title" id="canceled">
                  <div className="principal">
                    {isCode && size === 1 ? '' : <div className="circle" />}
                    <h4>{translate('TasksOptionsKanbanList.canceledText')}</h4>
                  </div>
                  <span>
                    {tasksCanceledTotal}{' '}
                    {translate('TasksOptionsKanbanList.tasksText')}
                  </span>
                </div>
              </div>

              <InfiniteScroll
                dataLength={taskCanceled.length}
                hasMore={taskCanceled.length !== tasksCanceledTotal}
                next={loadDataCanceled}
                loader={<LoadingOutlined />}
                scrollableTarget="scrollableDiv"
                height={'70vh'}
              >
                <Board isCode={isCode && size === 1}>
                  <div className="cards">
                    {taskCanceled?.map((taskStatus) => (
                      <CardsKanban
                        taskStatusData={taskStatus}
                        key={taskStatus._id}
                      />
                    ))}
                  </div>
                </Board>
              </InfiniteScroll>
            </Wrapper>

            <Wrapper>
              <div>
                <div className="title" id="finished">
                  <div className="principal">
                    {isCode && size === 1 ? '' : <div className="circle" />}
                    <h4>{translate('TasksOptionsKanbanList.finishedText')}</h4>
                  </div>
                  <span>
                    {tasksFinishedTotal}{' '}
                    {translate('TasksOptionsKanbanList.tasksText')}
                  </span>
                </div>
              </div>

              <InfiniteScroll
                dataLength={taskFinished.length}
                hasMore={taskFinished.length !== tasksFinishedTotal}
                next={loadDataFinished}
                loader={<LoadingOutlined />}
                scrollableTarget="scrollableDiv"
                height={'70vh'}
              >
                <Board isCode={isCode && size === 1}>
                  <div className="cards">
                    {taskFinished?.map((taskStatus) => (
                      <CardsKanban
                        taskStatusData={taskStatus}
                        key={taskStatus._id}
                      />
                    ))}
                  </div>
                </Board>
              </InfiniteScroll>
            </Wrapper>
          </>
        ) : (
          ''
        )}
      </Boards>
    </Container>
  )
}

export default ListKanban
