export default {
  pt: {
    MiscShareButton: {
      messageSuccess: 'Link copiado para a área de transferência!',
      tooltipTitle: 'Compartilhar',
      modalTitle: 'Compartilhar Link',
      whatsapp: 'WhatsApp',
      telegram: 'Telegram',
      email: 'Email',
      copyText: 'Copiar Link',
    },
  },
  en: {
    MiscShareButton: {
      messageSuccess: 'Link copied to clipboard!',
      tooltipTitle: 'To share',
      modalTitle: 'Share Link',
      whatsapp: 'WhatsApp',
      telegram: 'Telegram',
      email: 'E-mail',
      copyText: 'Copy Link',
    },
  },
  es: {
    MiscShareButton: {
      messageSuccess: '¡Enlace copiado al portapapeles!',
      tooltipTitle: 'Para compartir',
      modalTitle: 'Compartir enlace',
      whatsapp: 'WhatsApp',
      telegram: 'Telegram',
      email: 'Correo electrónico',
      copyText: 'Copiar enlace',
    },
  },
}
