import React from 'react'
import { Helmet } from 'react-helmet'

import { DashboardProvider } from '~/hooks/Dashboard/useDashboard'
import { ListStatusProvider } from '~/hooks/Status/useListStatus'

import { translate } from '~/utils/locale'

import { Container } from './styles'
import { TaskDashboardProvider } from '~/hooks/Dashboard/useTaskDashboard'
import DashboardTabs from '~/components/DataManipulation/Dashboard'
import { DashboardTasksProvider } from '~/hooks/Dashboard/useDashboardTasks'
import { TaskWorkingProvider } from '~/hooks/Tasks/Kanban/useTaskWorking'
import { TaskFinishedProvider } from '~/hooks/Tasks/Kanban/useTaskFinished'

import { TaskListProvider } from '~/hooks/Tasks/useListTasks'
import { TaskViewedProvider } from '~/hooks/Tasks/Kanban/useTaskViewed'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { LoadingIndicator } from '~/components/DataManipulation/Dashboard/Tasks/styles'
import { ListTicketsProvider } from '~/hooks/HelpDesk/useListTickets'
import { TaskReceivedProvider } from '~/hooks/Tasks/Kanban/useTaskReceived'
import { TaskEnRouteProvider } from '~/hooks/Tasks/Kanban/useTaskEnRoute'
import { TaskBreakProvider } from '~/hooks/Tasks/Kanban/useTaskBreak'
import { TaskCanceledProvider } from '~/hooks/Tasks/Kanban/useTaskCanceled'
import { TaskCheckInProvider } from '~/hooks/Tasks/Kanban/useTaskCheckIn'

const Dashboard: React.FC = () => {
  const { selectedCompany } = useCompanies()

  return (
    <Container>
      <Helmet>
        <title>{translate('Dashboard.helmetTitle')}</title>
      </Helmet>

      {selectedCompany ? (
        <DashboardProvider>
          <ListStatusProvider>
            <TaskDashboardProvider>
              <DashboardTasksProvider>
                <TaskViewedProvider>
                  <TaskWorkingProvider>
                    <TaskFinishedProvider>
                      <TaskListProvider>
                        <ListTicketsProvider>
                          <TaskReceivedProvider>
                            <TaskEnRouteProvider>
                              <TaskBreakProvider>
                                <TaskCanceledProvider>
                                  <TaskCheckInProvider>
                                    <DashboardTabs />
                                  </TaskCheckInProvider>
                                </TaskCanceledProvider>
                              </TaskBreakProvider>
                            </TaskEnRouteProvider>
                          </TaskReceivedProvider>
                        </ListTicketsProvider>
                      </TaskListProvider>
                    </TaskFinishedProvider>
                  </TaskWorkingProvider>
                </TaskViewedProvider>
              </DashboardTasksProvider>
            </TaskDashboardProvider>
          </ListStatusProvider>
        </DashboardProvider>
      ) : (
        <LoadingIndicator />
      )}
    </Container>
  )
}

export default Dashboard
