/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface EquipmentsTasks {
  id: string
}

interface TaskTypeProps {
  _id: string
  title: string
  runtime: string
  tolerance_time: string
  forms?: Array<{ form_id: string }>
  description: string
}

interface Address {
  city: string
  id: string
  location: {
    x: number
    y: number
  }
  neighborhood: string
  number: number
  state: string
  street: string
  zip_code: string
  complement: string
}

interface Responsible {
  id: string
  type: string
  data: {
    contact: {
      name: string
    }
    title: string
  }
}

interface Report {
  created_at: Date
  id: string
  type: string
  value: string
  title: string
  from: string
  contributor: string
  file_url: string
}

interface Status {
  type: string
  created_at: Date
  collaborator_id: string
  location: {
    lat: number
    long: number
  }
}

interface Customer {
  contact: {
    name: string
  }
  parent_cnpj: string
}

interface Historic {
  id: string
  customer_id: string
  task_type_id: string
  responsible: string
  date: Date
  priority: string
  description: string
  value: number
  duration_time: string
  external_code: string
  created_at: Date
  address: Address
}

interface Financial {
  task_value?: number
  amount_hours: number
  amount_hours_additional?: number
  number_km: number
  number_km_additional?: number
}

interface FinancialReceive {
  receive?: number
  amount_hours_receive: number
  amount_hours_additional_receive?: number
  number_km_receive: number
  number_km_additional_receive?: number
}

export interface TaskProps {
  checkin_type: string
  createdAt: string
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  description: string
  customer_id: string
  _id: string
  internal_code: string
  current_status: string
  hours: string
  currentDate: string
  task_type_id: string
  equipments: EquipmentsTasks[]
  address: Address
  responsible: Responsible
  reports: Report[]
  status: Status[]
  value: number
  receive: number
  note: string
  taskType: TaskTypeProps
  customer: Customer
  historic: Historic[]
  financial: Financial
  financialReceive: FinancialReceive
  forms: Array<{ form_id: string }>
}

interface TaskContextData {
  task?: TaskProps
  isLoading: boolean
  refetch: () => void
  setIsEdit(value: boolean): void
  isEdit?: boolean
  updateTask(valueForm?: object): void
  updateCanceledTask(valueForm?: object): void
  addReport(valueForm?: object): void
  updateReport(valueForm?: object, reportId?: string): void
  updateHistoric(valueForm?: object): void
  createEquipmentTask(valueForm?: object): void
  deleteEquipmentTask(equipmentId?: string): void
}

const TaskContext = createContext<TaskContextData>({} as TaskContextData)

export const TaskProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const { taskId } = useParams<{ taskId: string }>()

  const [isEdit, setIsEdit] = useState<boolean>()

  const {
    data: task,
    isLoading,
    refetch,
  } = useQuery(
    [`Detalhes da Tarefas${selectedCompany}`, selectedCompany],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/task/${taskId}`,
        )

        const { data } = response

        const dataFormatted = {
          checkin_type: data.checkin_type,
          createdAt: data.createdAt,
          date: data.date,
          duration_time: data.duration_time,
          external_code: data.external_code,
          is_enabled: data.is_enabled,
          priority: data.priority,
          description: data.description,
          _id: data._id,
          hours: data.hours,
          currentDate: data.currentDate,
          task_type_id: data.task_type_id,
          equipments: data.equipments,
          address: data.address,
          responsible: data.responsible,
          reports: data.reports,
          status: data.status,
          customer_id: data.customer_id,
          internal_code: data.internal_code,
          current_status: data.current_status,
          value: data.value,
          receive: data.receive,
          note: data.note,
          taskType: data.taskType,
          customer: data.customer,
          historic: data.historic,
          financial: data.financial,
          financialReceive: data.financialReceive,
          forms: data.forms,
        }

        return dataFormatted
      } catch (err: any) {
        ShowError(err.message, translate('useTask.getErrorMessage'), locale)
      }
    },
    {
      staleTime: 5000,
    },
  )

  async function createEquipmentTask(valueForm: object) {
    try {
      await api.post(
        `/company/${selectedCompany}/task/${taskId}/equipment`,
        valueForm,
      )

      refetch()
      message.success(translate('useTask.createSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useTask.createErrorMessage'), locale)
    }
  }

  async function updateTask(valueForm: object) {
    try {
      await api.put(`/company/${selectedCompany}/task/${taskId}`, valueForm)

      refetch()
      message.success(translate('useTask.updateSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useTask.updateErrorMessage'), locale)
    }
  }

  async function addReport(valueForm: object) {
    try {
      message.loading(translate('useTask.updateReportLoadingMessage'))

      await api.put(
        `/company/${selectedCompany}/task/${taskId}/report`,
        valueForm,
      )

      refetch()
      message.success(translate('useTask.updateReportSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useTask.updateReportErrorMessage'),
        locale,
      )
    }
  }

  async function updateReport(valueForm: object, reportId: string) {
    try {
      message.loading(translate('useTask.updateReportLoadingMessage'))

      await api.put(
        `/company/${selectedCompany}/task/${taskId}/report/${reportId}`,
        valueForm,
      )

      refetch()
      message.success(translate('useTask.updateReportSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useTask.updateReportErrorMessage'),
        locale,
      )
    }
  }

  async function updateHistoric(valueForm: object) {
    try {
      await api.put(
        `/company/${selectedCompany}/task/${taskId}/historic`,
        valueForm,
      )

      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useTask.updateReportErrorMessage'),
        locale,
      )
    }
  }

  async function deleteEquipmentTask(equipmentId: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/task/${taskId}/equipment/${equipmentId}`,
      )

      refetch()
      message.success(translate('useTask.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(err.message, translate('useTask.deleteErrorMessage'), locale)
    }
  }

  async function updateCanceledTask(valueForm: object) {
    try {
      await api.patch(
        `/company/${selectedCompany}/task/${taskId}/status/canceled`,
        valueForm,
      )

      refetch()
      message.success(translate('useTask.updateCanceledSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useTask.updateCanceledErrorMessage'),
        locale,
      )
    }
  }

  return (
    <TaskContext.Provider
      value={{
        task,
        isLoading,
        refetch,
        setIsEdit,
        isEdit,
        createEquipmentTask,
        updateTask,
        updateCanceledTask,
        addReport,
        updateReport,
        updateHistoric,
        deleteEquipmentTask,
      }}
    >
      {children}
    </TaskContext.Provider>
  )
}

export function useTask(): TaskContextData {
  const context = useContext(TaskContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
