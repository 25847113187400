/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import {
  FileUnknownOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Button,
  Modal,
  message,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'
import { format } from 'date-fns'
import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'

import { useListTask } from '~/hooks/Tasks/useListTasks'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface ModalTaskProps {
  form: any
}

interface EquipmentsTasks {
  id: string
}

interface TaskListProps {
  checkin_type: string
  createdAt: Date
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  _id: string
  hours: string
  currentDate: string
  internal_code: string
  current_status: string
  customer_id: string
  task_type_id: string
  responsible: {
    id: string
    type: string
    data: {
      contact: {
        name: string
      }
      title: string
    }
  }
  customer: {
    id: string
    contact: {
      name: string
    }
  }
  taskType: {
    _id: string
    title: string
    description: string
    runtime: string
    valueTask: number
  }
  address: {
    city: string
    id: string
    location: {
      x: number
      y: number
    }
    neighborhood: string
    number: any
    state: string
    street: string
    zip_code: string
    complement?: string
  }
  equipments: EquipmentsTasks[]
}

const TaskModal: React.FC<ModalTaskProps> = ({ form }) => {
  const { tasks, isFetching, setNumberPage, setPageSize, tasksTotal } =
    useListTask()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const {
    setClientData,
    setTaskTypeData,
    contributorData,
    setContributorData,
    setTeamData,
    setScaleId,
    setIsSaveTask,
    setType,
  } = useTaskCreate()

  async function handleClientTask(task: TaskListProps) {
    await setClientData({
      id: task.customer.id,
      name: task.customer.contact.name,
    })

    const { taskType } = task

    await setTaskTypeData({
      id: taskType._id,
      title: taskType.title,
      description: taskType.description,
      duration: taskType.runtime,
      valueTask: taskType.valueTask,
    })

    setType(
      task.responsible.type === 'collaborator'
        ? 'collaborator'
        : task.responsible.type === 'team'
          ? 'team'
          : 'any',
    )

    form.setFieldsValue({
      date: moment(task.date),
      priority: task.priority,
      zip_code: task.address.zip_code,
      street: task.address.street,
      city: task.address.city,
      neighborhood: task.address.neighborhood,
      state: task.address.state,
      complement: task.address.complement,
      number: task.address.number,
      latitude: task.address.location.x,
      longitude: task.address.location.y,
      type: task.responsible.type,
      id: task.responsible?.data?.contact?.name,
    })

    if (task.responsible.type === 'collaborator') {
      setContributorData({
        id: task.responsible?.id,
        name: task.responsible.data?.contact?.name,
      })
    } else {
      setScaleId({ id: '3424323r32', name: '' })
      setTeamData({
        id: task.responsible?.id,
        name: task.responsible?.data?.title,
      })
    }

    form.setFieldsValue({ id: contributorData?.name })

    setIsModalVisible(false)
    setIsSaveTask(true)

    message.success(translate('TasksCreateModalTask.confirmTaskData'))
  }

  const taskFormatted = tasks?.map((task: TaskListProps) => {
    const dataFormat = {
      ...task,
      initialTime: format(
        new Date(task.date),
        `dd/MM/yyyy '${translate('TasksOptionsTable.dateString')}' HH:mm'h'`,
      ),
    }

    return dataFormat
  })

  const columns: ColumnsType<TaskListProps> = [
    {
      title: translate('ClientsTable.columnActions'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Popconfirm
          title={translate('TasksCreateModalTask.addTaskPopconfirm')}
          onConfirm={() => handleClientTask(record)}
        >
          <Tooltip title={translate('TasksCreateModalTask.addTaskTooltip')}>
            <Button type="primary" shape="circle" icon={<PlusOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },
    {
      title: translate('TasksOptionsTable.internalCodeColumn'),
      dataIndex: 'internal_code',
      key: 'internal_code',
      render: (name: string, record) => (
        <Link target="_blank" to={`/tarefas/visualizar/${record._id}`}>
          {name}
        </Link>
      ),
    },
    {
      title: translate('TasksOptionsTable.responsibleColumn'),
      key: 'responsible',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <>
          {record.responsible?.type === 'collaborator' ? (
            <>
              {record?.responsible?.data?.contact?.name ? (
                <Text>{record?.responsible?.data?.contact?.name}</Text>
              ) : (
                <Text type="secondary">
                  <Space size="small">
                    <FileUnknownOutlined />
                    N/A
                  </Space>
                </Text>
              )}
            </>
          ) : (
            <>
              {record?.responsible?.data?.title ? (
                <Text>{record?.responsible?.data?.title}</Text>
              ) : (
                <Text type="secondary">
                  <Space size="small">
                    <FileUnknownOutlined />
                    N/A
                  </Space>
                </Text>
              )}
            </>
          )}
        </>
      ),
    },
    {
      title: translate('TasksOptionsTable.initialTimeColumn'),
      dataIndex: 'initialTime',
      key: 'date',
      sorter: (a: TaskListProps, b: TaskListProps) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: translate('TasksOptionsTable.taskTypeColumn'),
      key: 'taskType',
      dataIndex: ['taskType', 'title'],
    },
    {
      title: translate('TasksOptionsTable.statusColumn'),
      key: 'status',
      render: (record) => (
        <>
          {record.current_status === 'received' ? (
            <Tag color="yellow">
              {translate('TasksOptionsTable.statusReceived')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'viewed' ? (
            <Tag color="green">
              {translate('TasksOptionsTable.statusViewed')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'en-route' ? (
            <Tag color="green">
              {translate('TasksOptionsTable.statusEnRoute')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'working' ? (
            <Tag color="green">
              {translate('TasksOptionsTable.statusWorking')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'break' ? (
            <Tag color="red">{translate('TasksOptionsTable.statusBreak')}</Tag>
          ) : (
            ''
          )}
          {record.current_status === 'resume-working' ? (
            <Tag color="green">
              {translate('TasksOptionsTable.statusResumeWorking')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'canceled' ? (
            <Tag color="gray">
              {translate('TasksOptionsTable.statusCanceled')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'finished' ? (
            <Tag color="geekblue">
              {translate('TasksOptionsTable.statusFinished')}
            </Tag>
          ) : (
            ''
          )}
        </>
      ),
    },
  ]

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button
        icon={<PlusCircleOutlined />}
        onClick={showModal}
        style={{ width: '100%' }}
      >
        {translate('TasksCreateModalTask.addTaskButton')}
      </Button>
      <Modal
        title={translate('TasksCreateModalTask.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        style={{
          top: 30,
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
        footer={[
          <Space key="FooterProps">
            <Button key="back" onClick={closeModal}>
              {translate('TasksCreateModalTask.closeButton')}
            </Button>
          </Space>,
        ]}
      >
        <Table
          rowKey={(record) => record._id}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          pagination={{
            total: tasksTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            defaultPageSize: 10,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
            onChange: (page, pageSize) => {
              setPageSize(pageSize!)
              setNumberPage(page)
            },
          }}
          columns={columns}
          dataSource={taskFormatted}
          scroll={{ x: 1000 }}
        />
      </Modal>
    </>
  )
}

export default TaskModal
