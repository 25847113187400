export default {
  pt: {
    DashboardMap: {
      titleText: 'Mapa de Colaboradores',
      mapTitle: 'Mapa',
      mapHelp:
        'O mapa abaixo exibe a localização dos colaboradores de acordo com o respectivo endereço cadastrado.',
      viewContributorTooltip: 'Visualizar colaborador',
      nameLabel: 'Nome:',
      phoneLabel: 'Telefone:',
      emailLabel: 'E-mail:',
      evaluationLabel: 'Avaliação de Atendimento',
      internalEvaluationLabel: 'Avaliação Interna',
      addressTitle: 'Endereço',
      zipcodeLabel: 'CEP:',
      streetLabel: 'Logradouro:',
      cityLabel: 'Cidade:',
      complementLabel: 'Complemento:',
      responsibleLabel: 'Criado por: ',
      clientLabel: 'Cliente: ',
      dateLabel: 'Data da Tarefa: ',
      statusLabel: 'Status: ',
      taskTypeLabel: 'Tipo de Tarefa: ',
      taskLabel: 'Tarefa',
      taskDateLabel: 'Tarefas filtradas na data: ',
    },
  },
  en: {
    DashboardMap: {
      titleText: 'Collaborator Map',
      mapTitle: 'Map',
      mapHelp:
        'The map below shows the location of employees according to their registered address.',
      viewContributorTooltip: 'View contributor',
      nameLabel: 'Name:',
      phoneLabel: 'Phone:',
      emailLabel: 'Email:',
      evaluationLabel: 'Service Rating',
      internalEvaluationLabel: 'Internal Rating',
      addressTitle: 'Address',
      zipcodeLabel: 'Zipcode:',
      streetLabel: 'Street:',
      cityLabel: 'City:',
      complementLabel: 'Complement:',
      responsibleLabel: 'Created by: ',
      clientLabel: 'Client: ',
      dateLabel: 'Task Date: ',
      statusLabel: 'Status: ',
      taskTypeLabel: 'Task Type: ',
      taskLabel: 'Task',
      taskDateLabel: 'Tasks filtered by date: ',
    },
  },
  es: {
    DashboardMap: {
      titleText: 'Mapa de Colaboradores',
      mapTitle: 'Mapa',
      mapHelp:
        'El siguiente mapa muestra la ubicación de los empleados según su dirección registrada.',
      viewContributorTooltip: 'Ver colaborador',
      nameLabel: 'Nombre:',
      phoneLabel: 'Teléfono:',
      emailLabel: 'Correo electrónico:',
      evaluationLabel: 'Calificación del servicio',
      internalEvaluationLabel: 'Evaluación Interna',
      addressTitle: 'Dirección',
      zipcodeLabel: 'Código postal:',
      streetLabel: 'Calle:',
      cityLabel: 'Ciudad:',
      complementLabel: 'Complementar:',
      responsibleLabel: 'Criado por: ',
      clientLabel: 'Cliente: ',
      dateLabel: 'Fecha de la Tarea: ',
      statusLabel: 'Status: ',
      taskTypeLabel: 'Tipo de Tarea: ',
      taskLabel: 'Tarea',
      taskDateLabel: 'Tareas filtradas por fecha: ',
    },
  },
}
