/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  FileUnknownOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Steps,
  Table,
  Tag,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'

import Text from 'antd/lib/typography/Text'

import React, { useEffect, useState } from 'react'

import { translate } from '~/utils/locale'

import { useActivity } from '~/hooks/Maintenance/Activity/useActivity'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { Link } from 'react-router-dom'
import ActivityFilterModal from '../../../Activity/Filter'
import { Dayjs } from 'dayjs'
import { useContributorsTask } from '~/hooks/Tasks/Create/useContributorTask'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'
import { Container } from './styles'

interface ActivityProps {
  _id: string
  title: string
  createdAt: string
  component?: string
  activity?: string
  competence?: string
  periodicity: {
    type: string
    interval: number
    date: Date
  }
  data_font: string
}

const AddActivityModal: React.FC = () => {
  const { activity, isFetching, activityTotal } = useActivity()
  const { addActivity } = useShowMaintenance()

  const [activityId, setActivityId] = useState<React.Key[]>([])
  const [activityData, setActivityData] = useState<ActivityProps[]>([])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const { data } = useContributorsTask()

  const [current, setCurrent] = useState(0)
  const [date, setDate] = useState<Dayjs | null>(null)
  const [responsible, setResponsible] = useState<string>('')

  const [type, setType] = useState('')
  const [weekend, setWeekend] = useState('weekend')
  const [interval, setInterval] = useState<number | null>(null)

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']
    const regex = /^[0-9]*$/

    if (!regex.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault()
    }
  }

  const options = [
    { label: 'Somente durante a semana', value: 'days' },
    { label: 'Sábado e Domingo', value: 'weekend' },
    { label: 'Somente Sábado', value: 'saturday' },
    { label: 'Somente Domingo', value: 'sunday' },
  ]

  useEffect(() => {
    if (activityData.length > 0) {
      setType(activityData[0]?.periodicity?.type || '')
      setInterval(activityData[0]?.periodicity?.interval || null)
    } else {
      setType('')
      setInterval(null)
    }
  }, [isModalVisible, activityData])

  const next = () => setCurrent((prev) => prev + 1)
  const prev = () => setCurrent((prev) => prev - 1)

  const columns: ColumnsType<ActivityProps> = [
    {
      title: translate('MaintenanceSystemTab.Title'),
      key: 'title',
      dataIndex: 'title',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (title: string) =>
        title || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('SystemTable.nameColumn'),
      key: 'system',
      dataIndex: 'system',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (system: string) =>
        system || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('MaintenanceActivity.SelectComponents'),
      key: 'component',
      dataIndex: 'component',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (component: string) =>
        component || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('MaintenanceSystemTab.Activities'),
      key: 'activity',
      dataIndex: 'activity',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (activity: string) =>
        activity || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('MaintenanceSystemTab.Competence'),
      dataIndex: 'competence',
      key: 'competence',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (competence: string) =>
        competence || (
          <Text type="secondary">
            <Space size="small">
              <FileUnknownOutlined />
              N/A
            </Space>
          </Text>
        ),
    },
    {
      title: translate('MaintenanceSystemTab.Periodicity'),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: ActivityProps) => (
        <>
          {record.periodicity ? (
            <>
              {record.periodicity.type === 'day' ? (
                <>
                  {record.periodicity.interval === 1 ? (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.Day')}
                    </Text>
                  ) : (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.Days')}
                    </Text>
                  )}
                </>
              ) : null}

              {record.periodicity.type === 'sem' ? (
                <>
                  {record.periodicity.interval === 1 ? (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.Week')}
                    </Text>
                  ) : (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.Weeks')}
                    </Text>
                  )}
                </>
              ) : null}

              {record.periodicity.type === 'men' ? (
                <>
                  {record.periodicity.interval === 1 ? (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.Months')}
                    </Text>
                  ) : (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.MonthsPlural')}
                    </Text>
                  )}
                </>
              ) : null}

              {record.periodicity.type === 'anu' ? (
                <>
                  {record.periodicity.interval === 1 ? (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.Year')}
                    </Text>
                  ) : (
                    <Text>
                      {translate('MaintenanceSystemTab.Text')}{' '}
                      {record.periodicity.interval}{' '}
                      {translate('MaintenanceSystemTab.Years')}
                    </Text>
                  )}
                </>
              ) : null}
            </>
          ) : (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
  ]

  const rowSelection = {
    selectedRowKeys: activityId, // Mantém as seleções sincronizadas
    onChange: (selectedRowKeys: React.Key[], selectedRows: ActivityProps[]) => {
      setActivityId(selectedRowKeys)
      setActivityData(selectedRows)
    },
    getCheckboxProps: (record: ActivityProps) => ({
      disabled: record.title === 'Disabled User', // Exemplo de desativação
      name: record.title,
    }),
  }

  const showModal = () => {
    setIsModalVisible(true)
    setActivityId([])
  }

  const closeModal = () => {
    setIsModalVisible(false)
    setCurrent(0)
    setActivityData([])
    setType('')
    setInterval(null)
    setActivityId([])
  }

  useEffect(() => {
    if (!isModalVisible) {
      setActivityId([])
    }
  }, [isModalVisible])

  const steps = [
    {
      title: 'Data de Início da Atividade',
      content: (
        <>
          <Form.Item
            label="Selecione a data de início da atividade"
            name="date"
          >
            <DatePicker
              style={{ width: '100%' }}
              value={date}
              format="DD/MM/YYYY"
              onChange={(e: Dayjs | null) => setDate(e)}
            />
          </Form.Item>

          <Form.Item label="Executor/Fornecedor" name="responsible">
            <Select
              placeholder="Executor/Fornecedor da Atividade"
              onChange={(value) => setResponsible(value)}
              style={{ width: '100%' }}
            >
              {data?.map((c) => (
                <Select.Option value={c.contact.name} key={c.id}>
                  {c.contact.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Escolher atividade',
      content: (
        <Table
          rowKey={(record) => record?._id}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          pagination={{
            total: activityTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100', '200'],
            defaultPageSize: 5,
            showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
          }}
          columns={columns}
          size="middle"
          dataSource={activity}
          scroll={{ x: 1000 }}
        />
      ),
    },
    {
      title: 'Confirmar Periodicidade',
      content: (
        <>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    {translate('MaintenanceCreate.Type')}
                    <HelpTooltip title="Refere-se ao intervalo de repetição do evento ou ação." />
                  </Space>
                }
                name="type"
              >
                <Select
                  onChange={(e) => setType(e)}
                  style={{ width: '100%' }}
                  placeholder={translate('MaintenanceCreate.TypePlaceholder')}
                  defaultValue={
                    activityData ? activityData[0]?.periodicity?.type : ''
                  }
                >
                  <Select.Option value="day">
                    {translate('MaintenanceCreate.Daily')}
                  </Select.Option>
                  <Select.Option value="sem">
                    {translate('MaintenanceCreate.Weekly')}
                  </Select.Option>
                  <Select.Option value="men">
                    {translate('MaintenanceCreate.Monthly')}
                  </Select.Option>
                  <Select.Option value="anu">
                    {translate('MaintenanceCreate.Yearly')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    {translate('MaintenanceCreate.Interval')}
                    <HelpTooltip title="Representa o número de ciclos de frequência antes de cada repetição" />
                  </Space>
                }
                name="interval"
              >
                <InputNumber
                  onChange={(e: number | null) => setInterval(e)}
                  onKeyPress={handleKeyPress}
                  style={{ width: '100%' }}
                  placeholder={translate('MaintenanceCreate.EnterInterval')}
                  defaultValue={
                    activityData ? activityData[0]?.periodicity?.interval : 1
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {type === 'day' && interval === 1 ? (
            <Row>
              <Form.Item label="Atividades aos Finais de Semana" name="weekend">
                <Radio.Group
                  optionType="button"
                  options={options}
                  defaultValue="weekend"
                  onChange={(value) => setWeekend(value.target.value)}
                />
              </Form.Item>
            </Row>
          ) : (
            ''
          )}

          {type && interval && (
            <Container>
              {type === 'day' ? (
                <>
                  {interval === 1 ? (
                    <>
                      {weekend === 'weekend' ? (
                        <Tag className="tag" color="geekblue">
                          Atividade Diária - A cada {interval} dia e aos finais
                          de semana <br />
                        </Tag>
                      ) : (
                        ''
                      )}

                      {weekend === 'saturday' ? (
                        <Tag className="tag" color="geekblue">
                          Atividade Diária - A cada {interval} dia e aos sábados
                          <br />
                        </Tag>
                      ) : (
                        ''
                      )}

                      {weekend === 'sunday' ? (
                        <Tag className="tag" color="geekblue">
                          Atividade Diária - A cada {interval} dia e aos
                          domingos
                          <br />
                        </Tag>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <Tag className="tag" color="geekblue">
                      Atividade Diária - A cada {interval} dias <br />
                    </Tag>
                  )}
                </>
              ) : (
                ''
              )}

              {type === 'sem' ? (
                <>
                  {interval === 1 ? (
                    <Tag className="tag" color="purple">
                      Atividade Semanal - A cada uma semana <br />
                    </Tag>
                  ) : (
                    <Tag className="tag" color="purple">
                      Atividade Semanal - A cada {interval} semanas <br />
                    </Tag>
                  )}
                </>
              ) : (
                ''
              )}

              {type === 'men' ? (
                <>
                  {interval === 1 ? (
                    <Tag className="tag" color="green">
                      Atividade Mensal - A cada um mês <br />
                    </Tag>
                  ) : (
                    <Tag className="tag" color="green">
                      Atividade Mensal - A cada {interval} meses <br />
                    </Tag>
                  )}
                </>
              ) : (
                ''
              )}

              {type === 'anu' ? (
                <>
                  {interval === 1 ? (
                    <Tag className="tag" color="magenta">
                      Atividade Anual - A cada um ano <br />
                    </Tag>
                  ) : (
                    <Tag className="tag" color="magenta">
                      Atividade Anual - A cada {interval} anos <br />
                    </Tag>
                  )}
                </>
              ) : (
                ''
              )}
            </Container>
          )}
        </>
      ),
    },
  ]

  function AddActivity() {
    const equipmentsId = activityId.map((e) => {
      return {
        id: e,
        initialDate: date,
        responsible,
        periodicity: {
          interval,
          type,
          weekend,
        },
      }
    })

    const data = {
      activities: equipmentsId,
    }

    try {
      addActivity(data)
      setIsModalVisible(false)
      setCurrent(0)
      setActivityData([])
      setType('')
      setInterval(null)
      setActivityId([])
    } catch (err) {
      setIsModalVisible(false)
    }
  }

  return (
    <>
      <Button
        icon={<PlusCircleOutlined />}
        onClick={showModal}
        style={{ width: '100%' }}
        type="primary"
      >
        {translate('MaintenanceSystemTab.AddActivity')}
      </Button>

      <Modal
        title={translate('MaintenanceSystemTab.AddActivity')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width={current === 1 ? '1100px' : '700px'}
        footer={[
          <Space key="FooterProps" size={current !== 2 ? 650 : 430}>
            <Space>
              {current > 0 && <Button onClick={() => prev()}>Voltar</Button>}
              {current < steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => next()}
                  disabled={
                    (current === 0 && !date) ||
                    (current === 1 && activityId.length === 0)
                  }
                >
                  Próximo
                </Button>
              )}
            </Space>

            <Space>
              {current === 1 ? (
                <>
                  <ActivityFilterModal />
                  <Link to="/atividades/novo" target="_blank">
                    <Button type="primary" icon={<PlusOutlined />}>
                      {translate('MaintenanceSystemTab.NewActivity')}
                    </Button>
                  </Link>
                </>
              ) : (
                ''
              )}

              {activityId.length !== 0 && current === 2 ? (
                <Button
                  type="primary"
                  onClick={AddActivity}
                  disabled={!type || !interval}
                >
                  {translate('MaintenanceSystemTab.AddActivity')}
                </Button>
              ) : (
                ''
              )}
            </Space>
          </Space>,
        ]}
      >
        <Steps current={current}>
          {steps.map((item, index) => (
            <Steps.Step key={index} title={item.title} />
          ))}
        </Steps>
        <Form layout="vertical" style={{ marginTop: 24 }}>
          {steps[current].content}
        </Form>
      </Modal>
    </>
  )
}

export default AddActivityModal
