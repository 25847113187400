import React from 'react'

import { Container, ContainerCharts, ContainerMap } from './styles'
import CardsTasks from './Cards'
import TasksTotalLineChart from '../Charts/TasksTotalLineChart'
import TasksByValuePieChart from '../Charts/TasksByValuePieChart'

import TasksByContributorAndValuePieChart from '../Charts/TasksByContributorAndValuePieChart'

import { useTaskWorking } from '~/hooks/Tasks/Kanban/useTaskWorking'
import { useTaskFinished } from '~/hooks/Tasks/Kanban/useTaskFinished'

import { useListTask } from '~/hooks/Tasks/useListTasks'
import { useTaskViewed } from '~/hooks/Tasks/Kanban/useTaskViewed'

import { useListTickets } from '~/hooks/HelpDesk/useListTickets'
import DashboardSkeleton from './skeleton'
import { translate } from '~/utils/locale'
import { Col, Typography } from 'antd'
import { useTaskReceived } from '~/hooks/Tasks/Kanban/useTaskReceived'
import { useTaskCheckIn } from '~/hooks/Tasks/Kanban/useTaskCheckIn'
import { useTaskEnRoute } from '~/hooks/Tasks/Kanban/useTaskEnRoute'
import { useTaskBreak } from '~/hooks/Tasks/Kanban/useTaskBreak'
import { useTaskCanceled } from '~/hooks/Tasks/Kanban/useTaskCanceled'
import { useDashboard } from '~/hooks/Dashboard/useDashboard'
import TasksValueLineChart from '../Charts/TasksValueLineChart'

const Initial: React.FC = () => {
  const {
    taskReceived,
    isLoading: isLoadingReceived,
    tasksReceivedTotal,
  } = useTaskReceived()
  const {
    taskViewed,
    isLoading: isLoadingViewed,
    tasksViewedTotal,
  } = useTaskViewed()
  const {
    taskCheckIn,
    isLoading: isLoadingCheckIn,
    tasksCheckInTotal,
  } = useTaskCheckIn()
  const {
    taskEnRoute,
    tasksEnRouteTotal,
    isLoading: isLoadEnRoute,
  } = useTaskEnRoute()
  const {
    taskWorking,
    tasksWorkingTotal,
    isLoading: isLoadWork,
  } = useTaskWorking()
  const { taskBreak, tasksBreakTotal, isLoading: isLoadBreak } = useTaskBreak()
  const {
    taskCanceled,
    tasksCanceledTotal,
    isLoading: isLoadCancel,
  } = useTaskCanceled()
  const {
    taskFinished,
    tasksFinishedTotal,
    isLoading: isLoadFinish,
  } = useTaskFinished()
  const { tasksTotal, isLoading } = useListTask()
  const { ticketsTotal, isLoading: isLoadingTicket, tickets } = useListTickets()

  const { contribuitors, isLoading: isLoadingContributors } = useDashboard()

  if (
    isLoadWork ||
    isLoadFinish ||
    isLoadingViewed ||
    isLoading ||
    isLoadingTicket ||
    isLoadingReceived ||
    isLoadingCheckIn ||
    isLoadEnRoute ||
    isLoadCancel ||
    isLoadBreak ||
    isLoadingContributors ||
    taskBreak === undefined ||
    taskReceived === undefined ||
    taskCheckIn === undefined ||
    taskEnRoute === undefined ||
    taskCanceled === undefined ||
    taskWorking === undefined ||
    taskFinished === undefined ||
    taskViewed === undefined ||
    tasksTotal === undefined ||
    ticketsTotal === undefined ||
    contribuitors === undefined
  ) {
    return <DashboardSkeleton />
  }

  const newTickets = tickets?.filter((ticket) => ticket.status === 'new')
  const workingTickets = tickets?.filter(
    (ticket) => ticket.status === 'working',
  )
  const completedTickets = tickets?.filter(
    (ticket) => ticket.status === 'completed',
  )
  const canceledTickets = tickets?.filter(
    (ticket) => ticket.status === 'canceled',
  )

  const total = contribuitors?.length

  const enabledContribuitors = contribuitors?.filter((c) => {
    return c.status?.type === 'enabled'
  })

  const enabledWithRestrictionsContribuitors = contribuitors?.filter((c) => {
    return c.status?.type === 'enabled_with_restrictions'
  })

  const blockedContribuitors = contribuitors?.filter((c) => {
    return c.status?.type === 'blocked'
  })

  const data = [
    {
      id: 1,
      backgroundColor: '#252525',
      titleColor: 'white',
      title: translate('DashboardTasksInitial.totalTasks'),
      value: tasksTotal,
    },
    {
      id: 2,
      backgroundColor: '#3C8ACB',
      titleColor: 'white',
      title: 'Enviadas',
      value: tasksReceivedTotal,
    },
    {
      id: 3,
      backgroundColor: '#17EAD9',
      titleColor: 'white',
      title: 'Visualizadas',
      value: tasksViewedTotal,
    },
    {
      id: 4,
      backgroundColor: '#49c870',
      titleColor: 'white',
      title: 'Aceitas',
      value: tasksCheckInTotal,
    },
    {
      id: 5,
      backgroundColor: '#53109a',
      titleColor: 'white',
      title: 'A caminho',
      value: tasksEnRouteTotal,
    },
    {
      id: 5,
      backgroundColor: '#3969B7',
      titleColor: 'white',
      title: 'Executando',
      value: tasksWorkingTotal,
    },
    {
      id: 6,
      backgroundColor: '#eccc01',
      titleColor: 'white',
      title: 'Pausadas',
      value: tasksBreakTotal,
    },
    {
      id: 7,
      backgroundColor: '#D82143',
      titleColor: 'white',
      title: 'Canceladas',
      value: tasksCanceledTotal,
    },
    {
      id: 8,
      backgroundColor: '#3d3d3d',
      titleColor: 'white',
      title: 'Finalizadas',
      value: tasksFinishedTotal,
    },
  ]

  const dataTickets = [
    {
      id: 9,
      title: 'Total de Tickets',
      value: ticketsTotal,
      backgroundColor: '#3C8ACB',
    },
    {
      id: 10,
      title: 'Novos',
      value: newTickets?.length,
      backgroundColor: '#3C8ACB',
    },
    {
      id: 11,
      title: 'Em atendimento',
      value: workingTickets?.length,
      backgroundColor: '#49c870',
    },
    {
      id: 12,
      title: 'Cancelados',
      value: canceledTickets?.length,
      backgroundColor: '#D82143',
    },
    {
      id: 13,
      title: 'Concluídos',
      value: completedTickets?.length,
      backgroundColor: '#7f3ed3',
    },
  ]

  const dataContributors = [
    {
      id: 14,
      title: 'Total de Colaboradores',
      value: total,
      backgroundColor: '#3C8ACB',
    },
    {
      id: 15,
      title: 'Habilitados',
      value: enabledContribuitors.length,
      backgroundColor: '#7f3ed3',
    },
    {
      id: 16,
      title: 'Habilitados c/ Restrição',
      value: enabledWithRestrictionsContribuitors.length,
      backgroundColor: '#49c870',
    },
    {
      id: 17,
      title: 'Bloqueados',
      value: blockedContribuitors.length,
      backgroundColor: '#D82143',
    },
  ]

  return (
    <Container>
      <Col>
        <Typography.Title level={5}>
          {translate('DashboardTasksInitial.filterText')}
        </Typography.Title>
        <CardsTasks
          data={data}
          tickets={dataTickets}
          contributors={dataContributors}
        />
      </Col>

      <ContainerCharts>
        <TasksTotalLineChart type="dash" filterInfoVisibility />
        <TasksByValuePieChart type="dash" filterInfoVisibility />
      </ContainerCharts>

      <ContainerMap>
        <TasksValueLineChart filterInfoVisibility />
        <TasksByContributorAndValuePieChart type="dash" filterInfoVisibility />
      </ContainerMap>
    </Container>
  )
}

export default Initial
