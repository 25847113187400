export default {
  pt: {
    TasksOptionsKanbanList: {
      receivedText: 'Enviadas',
      viewedText: 'Visualizadas',
      acceptedText: 'Aceitou',
      tasksText: 'tarefas',
      enRouteText: 'A caminho',
      workingText: 'Executando',
      breakText: 'Pausadas',
      canceledText: 'Canceladas',
      finishedText: 'Finalizadas',
    },
  },
  en: {
    TasksOptionsKanbanList: {
      receivedText: 'Sent',
      viewedText: 'Viewed',
      acceptedText: 'Accepted',
      tasksText: 'tasks',
      enRouteText: 'On the way',
      workingText: 'Running',
      breakText: 'Paused',
      canceledText: 'Canceled',
      finishedText: 'Finished',
    },
  },
  es: {
    TasksOptionsKanbanList: {
      receivedText: 'Enviado',
      viewedText: 'Visto',
      acceptedText: 'Aceptado',
      tasksText: 'tareas',
      enRouteText: 'En camino',
      workingText: 'Ejecución',
      breakText: 'En Pausa',
      canceledText: 'Cancelado',
      finishedText: 'Terminadas',
    },
  },
}
