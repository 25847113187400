export default {
  pt: {
    MaintenancePlanningTableLegend: {
      statusReceived: 'Enviada',
      statusViewed: 'Visualizada',
      statusEnRoute: 'A caminho',
      statusWorking: 'Sendo realizada',
      statusResumeWorking: 'Retomada',
      statusBreak: 'Pausada',
      statusCanceled: 'Cancelada',
      statusFinished: 'Finalizada',
      statusUndefined: 'Status não definido',
    },
  },
  en: {
    MaintenancePlanningTableLegend: {
      statusReceived: 'Submitted',
      statusViewed: 'Viewed',
      statusEnRoute: 'On the way',
      statusWorking: 'Being performed',
      statusResumeWorking: 'Resume',
      statusBreak: 'Paused',
      statusCanceled: 'Canceled',
      statusFinished: 'Finished',
      statusUndefined: 'Status not defined',
    },
  },
  es: {
    MaintenancePlanningTableLegend: {
      statusReceived: 'Enviada',
      statusViewed: 'Vista',
      statusEnRoute: 'En camino',
      statusWorking: 'Que se está realizando',
      statusResumeWorking: 'Reanudar',
      statusBreak: 'En pausa',
      statusCanceled: 'Cancelada',
      statusFinished: 'Terminada',
      statusUndefined: 'Estado no definido',
    },
  },
}
