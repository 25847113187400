export default {
  pt: {
    HelpDeskTable: {
      codeColumn: 'Código',
      ticketColumn: 'Assunto',
      solicitationColumn: 'Tipo de Solicitação',
      clientColumn: 'Cliente',
      statusColumn: 'Status',
      priorityColumn: 'Prioridade',
      validateColumn: 'Validade',
      dateColumn: 'Data de abertura',
      tasksColumn: 'Tarefas',
      actionsColumn: 'Ações',
      deletePopconfirm: 'Deseja mesmo deletar o ticket?',
      deleteTooltip: 'Deletar',
      viewTooltip: 'Ver Ticket',
      anyTaskText: 'Nenhuma tarefa vinculada',
      oneTaskText: '1 tarefa vinculada',
      manyTaskText: 'tarefas vinculadas',
    },
  },
  en: {
    HelpDeskTable: {
      codeColumn: 'Code',
      ticketColumn: 'Subject',
      solicitationColumn: 'Request Type',
      clientColumn: 'Client',
      statusColumn: 'Status',
      priorityColumn: 'Priority',
      validateColumn: 'Validity',
      dateColumn: 'Opening date',
      tasksColumn: 'Tasks',
      actionsColumn: 'Actions',
      deletePopconfirm: 'Do you really want to delete the ticket?',
      deleteTooltip: 'Delete',
      viewTooltip: 'View Ticket',
      anyTaskText: 'No linked tasks',
      oneTaskText: '1 linked task',
      manyTaskText: 'linked tasks',
    },
  },
  es: {
    HelpDeskTable: {
      codeColumn: 'Código',
      ticketColumn: 'Sujeto',
      solicitationColumn: 'Tipo de solicitud',
      clientColumn: 'Cliente',
      statusColumn: 'Status',
      priorityColumn: 'Prioridad',
      validateColumn: 'Validez',
      dateColumn: 'Fecha de apertura',
      tasksColumn: 'Tareas',
      actionsColumn: 'Acciones',
      deletePopconfirm: '¿Realmente desea eliminar el ticket?',
      deleteTooltip: 'Borrar',
      viewTooltip: 'Ver Ticket',
      anyTaskText: 'Sin tareas vinculadas',
      oneTaskText: '1 tarea vinculada',
      manyTaskText: 'tareas vinculadas',
    },
  },
}
