/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useCallback, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import api from '~/services/api'

import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface User {
  id: string
  email: string
  active: boolean
}

interface AuthState {
  token: string
  user: User
}

interface SignInCredentials {
  email: string
  password?: string
}

interface AuthContextData {
  token: string
  user: User
  // eslint-disable-next-line no-unused-vars
  signIn(credentials: SignInCredentials): Promise<void>
  updateCompanie(companyId: string, dataForm?: any): void
  signOut(): void
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

const AuthProvider: React.FC = ({ children }) => {
  const typeAuth = localStorage.getItem('typeAuth')

  const history = useHistory()

  const { locale } = useLocale()

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Gstor:token')
    const user = localStorage.getItem('@Gstor:user')

    if (token && user) {
      return { token, user: JSON.parse(user) }
    }

    return {} as AuthState
  })

  const signIn = useCallback(
    async ({ email, password }) => {
      try {
        if (typeAuth === 'panel') {
          const response = await api.post('sessions/auth/panel', {
            email,
          })

          const { token, user } = response.data

          localStorage.setItem('@Gstor:token', token)
          localStorage.setItem('@Gstor:user', JSON.stringify(user))

          api.defaults.headers.Authorization = `Bearer ${token}`

          setData({ token, user })
        } else {
          const response = await api.post('sessions', {
            email,
            password,
          })

          const { token, user } = response.data

          localStorage.setItem('@Gstor:token', token)
          localStorage.setItem('@Gstor:user', JSON.stringify(user))

          api.defaults.headers.Authorization = `Bearer ${token}`

          setData({ token, user })
        }
      } catch (err: any) {
        ShowError(err.message, translate('AuthContext.errorMessage'), locale)
      }
    },
    [locale, typeAuth],
  )

  const signOut = useCallback(() => {
    history.go(0)

    localStorage.removeItem('typeAuth')
    localStorage.removeItem('userEmail')
    localStorage.removeItem('@Gstor:token')
    localStorage.removeItem('@Gstor:user')
    localStorage.removeItem('@Gstor:currentCompany')
    localStorage.removeItem('@Gstor:contributorFilterOptions')
    localStorage.removeItem('@Gstor:scalesFilterOptions')
    setData({} as AuthState)
  }, [history])

  async function updateCompanie(companyId: string, valueForm?: any) {
    try {
      await api.put(`/company/${companyId}/acces`, valueForm)
    } catch (err: any) {}
  }

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        user: data.user,
        signIn,
        signOut,
        updateCompanie,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}

export { AuthProvider, useAuth }
