import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Layout, Menu, Grid, Row, Tooltip } from 'antd'
import {
  // AreaChartOutlined,
  BarChartOutlined,
  BookOutlined,
  BuildOutlined,
  CalendarOutlined,
  CarOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  ClusterOutlined,
  CodeOutlined,
  CrownOutlined,
  DashboardOutlined,
  DollarOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  FormOutlined,
  IdcardOutlined,
  KeyOutlined,
  PayCircleOutlined,
  PhoneOutlined,
  ProjectOutlined,
  SafetyOutlined,
  ScheduleOutlined,
  SettingOutlined,
  StarOutlined,
  SyncOutlined,
  TagOutlined,
  TagsOutlined,
  TeamOutlined,
  ToolOutlined,
  TransactionOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons'
import SubMenu from 'antd/lib/menu/SubMenu'

import { Logo, Container } from './styles'

import LogoGestor from '~/assets/logo_icon.svg'
import { translate } from '~/utils/locale'

const { Sider } = Layout

interface SidebarProps {
  isCollapsed: boolean
  onCollapseSidebar: () => void
}

const { useBreakpoint } = Grid

const Sidebar: React.FC<SidebarProps> = ({
  isCollapsed,
  onCollapseSidebar,
}) => {
  const location = useLocation()
  const screens = useBreakpoint()

  const localização = location.pathname.split('/')[1]

  // const enabledCompany = localStorage.getItem('@Gstor:enabledCompany')

  return (
    <Row>
      <Container>
        <Sider
          trigger={screens.sm && null}
          breakpoint="lg"
          collapsedWidth={screens.xs ? 0 : 55}
          collapsed={!isCollapsed}
          onCollapse={onCollapseSidebar}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'sticky',
            top: 0,
            left: 0,
            boxShadow: 'rgba(0, 0, 0, 0.281) 0px 5px 15px',
            borderRadius: '0 0 0.3rem 0',
            background: 'rgb(10, 11, 12)',
          }}
          theme="dark"
        >
          {isCollapsed ? (
            <Logo>
              <img src={LogoGestor} alt="Logo Gstor" />
              <h2>Gstor</h2>
            </Logo>
          ) : (
            <Logo>
              <img src={LogoGestor} alt="Logo Gstor" />
            </Logo>
          )}

          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['/']}
            defaultOpenKeys={['dashboard']}
            selectedKeys={[localização]}
          >
            {/* {enabledCompany === 'true' ? ( */}
            <>
              {/* <Tooltip title="Em Manutenção, Disponível em Breve!"> */}
              <SubMenu
                key="Dashboard"
                title={
                  <Tooltip title={translate('Sidebar.dashboard')}>
                    {translate('Sidebar.dashboard')}
                  </Tooltip>
                }
                // disabled
                icon={<DashboardOutlined />}
              >
                <Menu.ItemGroup>
                  <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
                    <Tooltip title={translate('Sidebar.dashboard')}>
                      <Link to="/dashboard">
                        {translate('Sidebar.dashboard')}
                      </Link>
                    </Tooltip>
                  </Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>

              <SubMenu
                key="helpdesk"
                icon={<SettingOutlined />}
                title={translate('Sidebar.helpdesk')}
              >
                <Menu.Item key="tickets" icon={<UserOutlined />}>
                  <Tooltip title="Tickets">
                    <Link to="/helpdesk">Tickets</Link>
                  </Tooltip>
                </Menu.Item>

                <Menu.SubMenu
                  key="configuracoes"
                  title={translate('Sidebar.settingsTitle')}
                  icon={<SettingOutlined />}
                >
                  <Menu.Item key="tiposSolicitacao" icon={<UserOutlined />}>
                    <Tooltip title={translate('Sidebar.solicitationType')}>
                      <Link to="/tiposSolicitacao">
                        {translate('Sidebar.solicitationType')}
                      </Link>
                    </Tooltip>
                  </Menu.Item>
                </Menu.SubMenu>
              </SubMenu>

              <SubMenu
                key="Tarefas"
                title={
                  <Tooltip title={translate('Sidebar.taskTitle')}>
                    {translate('Sidebar.taskTitle')}
                  </Tooltip>
                }
                icon={<CheckSquareOutlined />}
              >
                <Menu.ItemGroup>
                  <Menu.Item key="dashboardTasks" icon={<BarChartOutlined />}>
                    <Tooltip title={translate('Sidebar.dashboardTasks')}>
                      <Link to="/dashboardTasks">
                        {translate('Sidebar.dashboardTasks')}
                      </Link>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item key="agenda" icon={<CalendarOutlined />}>
                    <Tooltip title={translate('Sidebar.taskSchedule')}>
                      <Link to="/agenda">
                        {translate('Sidebar.taskSchedule')}
                      </Link>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item key="tarefas" icon={<CheckSquareOutlined />}>
                    <Tooltip title={translate('Sidebar.taskList')}>
                      <Link to="/tarefas">{translate('Sidebar.taskList')}</Link>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item key="kanban" icon={<ProjectOutlined />}>
                    <Tooltip title={translate('Sidebar.taskKanban')}>
                      <Link to="/kanban">
                        {translate('Sidebar.taskKanban')}
                      </Link>
                    </Tooltip>
                  </Menu.Item>

                  <Menu.SubMenu
                    key="configuracoes"
                    title={
                      <Tooltip title={translate('Sidebar.settingsTitle')}>
                        {translate('Sidebar.settingsTitle')}
                      </Tooltip>
                    }
                    icon={<SettingOutlined />}
                  >
                    <Menu.Item key="tiposDeTarefas" icon={<TagOutlined />}>
                      <Tooltip title={translate('Sidebar.taskType')}>
                        <Link to="/tiposDeTarefas">
                          {translate('Sidebar.taskType')}
                        </Link>
                      </Tooltip>
                    </Menu.Item>
                    <Menu.Item key="formularios" icon={<FormOutlined />}>
                      <Tooltip title={translate('Sidebar.forms')}>
                        <Link to="/formularios">
                          {translate('Sidebar.forms')}
                        </Link>
                      </Tooltip>
                    </Menu.Item>
                  </Menu.SubMenu>

                  {/* <Menu.Item
                      key="motivosDePausa"
                      icon={<DisconnectOutlined />}
                    >
                      <Tooltip title={translate('Sidebar.taskBreakReasons')}>
                      <Link to="/motivosDePausa">
                        {translate('Sidebar.taskBreakReasons')}
                      </Link>
                      </Tooltip>
                    </Menu.Item> */}
                </Menu.ItemGroup>
              </SubMenu>

              <SubMenu
                key="colaborador"
                title={
                  <Tooltip title={translate('Sidebar.collaboratorsTitle')}>
                    {translate('Sidebar.collaboratorsTitle')}
                  </Tooltip>
                }
                icon={<TeamOutlined />}
              >
                {/* <Tooltip title={translate('Sidebar.soonTooltip')}>
                  <Menu.Item
                    key="dashboardContributors"
                    icon={<PieChartOutlined />}
                    disabled
                  >
                    <Link to="/dashboardContributors">
                      {translate('Sidebar.dashboardContributors')}
                    </Link>
                  </Menu.Item>
                </Tooltip> */}

                <Menu.Item key="colaboradores" icon={<UnorderedListOutlined />}>
                  <Tooltip title={translate('Sidebar.collaboratorsList')}>
                    <Link to="/colaboradores">
                      {translate('Sidebar.collaboratorsList')}
                    </Link>
                  </Tooltip>
                </Menu.Item>

                <Menu.Item key="equipes" icon={<UsergroupAddOutlined />}>
                  <Tooltip title={translate('Sidebar.collaboratorsTeams')}>
                    <Link to="/equipes">
                      {translate('Sidebar.collaboratorsTeams')}
                    </Link>
                  </Tooltip>
                </Menu.Item>

                <Menu.SubMenu
                  key="configuracoes"
                  title={
                    <Tooltip title={translate('Sidebar.settingsTitle')}>
                      {translate('Sidebar.settingsTitle')}
                    </Tooltip>
                  }
                  icon={<SettingOutlined />}
                >
                  <Menu.Item key="habilidades" icon={<StarOutlined />}>
                    <Tooltip title={translate('Sidebar.collaboratorsSkills')}>
                      <Link to="/habilidades">
                        {translate('Sidebar.collaboratorsSkills')}
                      </Link>
                    </Tooltip>
                  </Menu.Item>

                  <Menu.Item key="escalas" icon={<ClockCircleOutlined />}>
                    <Tooltip title={translate('Sidebar.collaboratorsScales')}>
                      <Link to="/escalas">
                        {translate('Sidebar.collaboratorsScales')}
                      </Link>
                    </Tooltip>
                  </Menu.Item>

                  <Menu.Item key="status" icon={<SyncOutlined />}>
                    <Tooltip title={translate('Sidebar.collaboratorsStatus')}>
                      <Link to="/status">
                        {translate('Sidebar.collaboratorsStatus')}
                      </Link>
                    </Tooltip>
                  </Menu.Item>

                  <Menu.Item key="usuarios" icon={<KeyOutlined />}>
                    <Tooltip title={translate('Sidebar.settingsLogin')}>
                      <Link to="/usuarios">
                        {translate('Sidebar.settingsLogin')}
                      </Link>
                    </Tooltip>
                  </Menu.Item>

                  <Menu.Item key="cracha" icon={<IdcardOutlined />}>
                    <Tooltip title={translate('Sidebar.badgeTitle')}>
                      <Link to="/cracha">
                        {translate('Sidebar.badgeTitle')}
                      </Link>
                    </Tooltip>
                  </Menu.Item>
                </Menu.SubMenu>
              </SubMenu>

              <SubMenu
                key="cliente"
                title={
                  <Tooltip title={translate('Sidebar.clientsTitle')}>
                    {translate('Sidebar.clientsTitle')}
                  </Tooltip>
                }
                icon={<UserOutlined />}
              >
                {/* <Tooltip title={translate('Sidebar.soonTooltip')}>
                  <Menu.Item
                    key="dashboardCustomers"
                    icon={<AreaChartOutlined />}
                    disabled
                  >
                    <Link to="/dashboardCustomers">
                      {translate('Sidebar.dashboardCustomers')}
                    </Link>
                  </Menu.Item>
                </Tooltip> */}

                <Menu.Item key="clientes" icon={<UnorderedListOutlined />}>
                  <Tooltip title={translate('Sidebar.clientsList')}>
                    <Link to="/clientes">
                      {translate('Sidebar.clientsList')}
                    </Link>
                  </Tooltip>
                </Menu.Item>

                <Menu.Item key="clientePrincipal" icon={<CrownOutlined />}>
                  <Tooltip title={translate('Sidebar.clientsParent')}>
                    <Link to="/clientePrincipal">
                      {translate('Sidebar.clientsParent')}
                    </Link>
                  </Tooltip>
                </Menu.Item>

                <Menu.Item key="contatosCliente" icon={<PhoneOutlined />}>
                  <Tooltip title={translate('Sidebar.clientsContacts')}>
                    <Link to="/contatosCliente">
                      {translate('Sidebar.clientsContacts')}
                    </Link>
                  </Tooltip>
                </Menu.Item>

                <Menu.SubMenu
                  key="configuracoes"
                  title={
                    <Tooltip title={translate('Sidebar.settingsTitle')}>
                      {translate('Sidebar.settingsTitle')}
                    </Tooltip>
                  }
                  icon={<SettingOutlined />}
                >
                  <Menu.Item key="segmentos" icon={<ClusterOutlined />}>
                    <Tooltip title={translate('Sidebar.clientsSegments')}>
                      <Link to="/segmentos">
                        {translate('Sidebar.clientsSegments')}
                      </Link>
                    </Tooltip>
                  </Menu.Item>
                </Menu.SubMenu>
              </SubMenu>

              <SubMenu
                key="financial"
                title={
                  <Tooltip title={translate('Sidebar.financialTitle')}>
                    {translate('Sidebar.financialTitle')}
                  </Tooltip>
                }
                icon={<WalletOutlined />}
              >
                <Menu.Item key="values" icon={<TransactionOutlined />}>
                  <Tooltip title={translate('Sidebar.financialAgreed')}>
                    <Link to="/financeiro/valoresAcordados">
                      {translate('Sidebar.financialAgreed')}
                    </Link>
                  </Tooltip>
                </Menu.Item>
                <Menu.Item key="valuesReceive" icon={<DollarOutlined />}>
                  <Tooltip title={translate('Sidebar.financialReceive')}>
                    <Link to="/financeiro/valoresReceber">
                      {translate('Sidebar.financialReceive')}
                    </Link>
                  </Tooltip>
                </Menu.Item>
                <Menu.Item key="valuesPay" icon={<PayCircleOutlined />}>
                  <Tooltip title={translate('Sidebar.financialAmount')}>
                    <Link to="/financeiro/valoresPagar">
                      {translate('Sidebar.financialAmount')}
                    </Link>
                  </Tooltip>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="equipamento"
                title={
                  <Tooltip title={translate('Sidebar.equipmentsTitle')}>
                    {translate('Sidebar.equipmentsTitle')}
                  </Tooltip>
                }
                icon={<ToolOutlined />}
              >
                <Menu.Item key="equipamentos" icon={<UnorderedListOutlined />}>
                  <Tooltip title={translate('Sidebar.equipmentsList')}>
                    <Link to="/equipamentos">
                      {translate('Sidebar.equipmentsList')}
                    </Link>
                  </Tooltip>
                </Menu.Item>

                <Menu.SubMenu
                  key="configuracoes"
                  title={
                    <Tooltip title={translate('Sidebar.settingsTitle')}>
                      {translate('Sidebar.settingsTitle')}
                    </Tooltip>
                  }
                  icon={<SettingOutlined />}
                >
                  <Menu.Item key="categorias" icon={<TagsOutlined />}>
                    <Tooltip title={translate('Sidebar.equipmentsCategories')}>
                      <Link to="/categorias">
                        {translate('Sidebar.equipmentsCategories')}
                      </Link>
                    </Tooltip>
                  </Menu.Item>

                  <Menu.Item key="templates" icon={<FileProtectOutlined />}>
                    <Tooltip title={translate('Sidebar.equipmentsTemplates')}>
                      <Link to="/templates">
                        {translate('Sidebar.equipmentsTemplates')}
                      </Link>
                    </Tooltip>
                  </Menu.Item>
                </Menu.SubMenu>
              </SubMenu>

              <SubMenu
                key="maintenanceTitle"
                title={
                  <Tooltip title={translate('Sidebar.maintenance')}>
                    {translate('Sidebar.maintenance')}
                  </Tooltip>
                }
                icon={<BuildOutlined />}
              >
                <Menu.Item
                  title={translate('Sidebar.maintenance')}
                  key="maintenance"
                  icon={<BuildOutlined />}
                >
                  <Tooltip title={translate('Sidebar.maintenance')}>
                    <Link to="/manutencao">
                      {translate('Sidebar.maintenance')}
                    </Link>
                  </Tooltip>
                </Menu.Item>

                <Menu.Item
                  title={translate('Sidebar.systems')}
                  key="systems"
                  icon={<CodeOutlined />}
                >
                  <Tooltip title={translate('Sidebar.systems')}>
                    <Link to="/manutencao/sistemas">
                      {translate('Sidebar.systems')}
                    </Link>
                  </Tooltip>
                </Menu.Item>

                <Menu.Item
                  title="Atividades"
                  key="activities"
                  icon={<ScheduleOutlined />}
                >
                  <Tooltip title={translate('Sidebar.activity')}>
                    <Link to="/atividades">
                      {translate('Sidebar.activity')}
                    </Link>
                  </Tooltip>
                </Menu.Item>

                <Menu.Item
                  title="Databook"
                  key="databook"
                  icon={<BookOutlined />}
                >
                  <Tooltip title="Databook">
                    <Link to="/manutencao/databook">Databook</Link>
                  </Tooltip>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="relatorios"
                title={
                  <Tooltip title={translate('Sidebar.reportsTitle')}>
                    {translate('Sidebar.reportsTitle')}
                  </Tooltip>
                }
                icon={<FileSearchOutlined />}
              >
                <Menu.Item
                  title={translate('Sidebar.reportsTasksTitle')}
                  key="relatorioTarefas"
                  icon={<CheckSquareOutlined />}
                >
                  <Tooltip title={translate('Sidebar.reportsTasks')}>
                    <Link to="/relatorioTarefas">
                      {translate('Sidebar.reportsTasks')}
                    </Link>
                  </Tooltip>
                </Menu.Item>

                <Menu.Item
                  title={translate('Sidebar.reportsKmTitle')}
                  key="relatorioQuilometros"
                  icon={<CarOutlined />}
                >
                  <Tooltip title={translate('Sidebar.reportsKm')}>
                    <Link to="/relatorioQuilometros">
                      {translate('Sidebar.reportsKm')}
                    </Link>
                  </Tooltip>
                </Menu.Item>
              </SubMenu>

              <Menu.Item key="permissoes" icon={<SafetyOutlined />}>
                <Tooltip title={translate('Sidebar.settingsPermissions')}>
                  <Link to="/permissoes">
                    {translate('Sidebar.settingsPermissions')}
                  </Link>
                </Tooltip>
              </Menu.Item>
            </>
            {/* ) : (
              <Menu.Item key="dashboard" icon={<LayoutOutlined />}>
                <Tooltip title={translate('Sidebar.dashboard')}>
                  <Link to="/dashboard">{translate('Sidebar.dashboard')}</Link>
                </Tooltip>
              </Menu.Item>
            )} */}
          </Menu>
        </Sider>
      </Container>
    </Row>
  )
}

export default Sidebar
