/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import {
  Container,
  StatusTasks,
  StatusTasksContainer,
  Card,
  TicketsContainer,
  StatusTicketContainer,
  ContributorsContainer,
  RatesContainer,
  RatesCard,
} from './styles'

import { Link } from 'react-router-dom'
import {
  BarChartOutlined,
  FileOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { Rate, Tooltip } from 'antd'
import { useDashboard } from '~/hooks/Dashboard/useDashboard'

interface TasksProps {
  backgroundColor: string
  titleColor: string
  title: string
  value?: number
  id: number
}

interface DataProps {
  backgroundColor: string
  title: string
  value?: number
  id: number
}

interface CardProps {
  data: TasksProps[]
  tickets: DataProps[]
  contributors: DataProps[]
}

const CardsTasks: React.FC<CardProps> = ({ data, tickets, contributors }) => {
  const { contribuitors } = useDashboard()

  const internalContribuitors = contribuitors?.map((c) => {
    return c.internal_evaluation
  })

  const evaluationContribuitors = contribuitors?.map((c) => {
    return c.evaluation
  })

  const mediaInternal: number | undefined = internalContribuitors?.reduce(
    (totalM, valor, índice, array) => {
      totalM += valor
      if (índice === array.length - 1) {
        return totalM / array.length
      }
      return totalM
    },
    0,
  )

  const mediaEvaluation: number | undefined = evaluationContribuitors?.reduce(
    (totalM, valor, índice, array) => {
      totalM += valor
      if (índice === array.length - 1) {
        return totalM / array.length
      }
      return totalM
    },
    0,
  )

  return (
    <Container>
      <Tooltip title="Ver tarefas">
        <Link to="/tarefas" target="_blank">
          <Card>
            <div className="header">
              <h3>Tarefas</h3>

              <div className="icon">
                <BarChartOutlined />
              </div>
            </div>

            <div className="content">
              <div className="totalTasks">
                <h4>{data[0].title}</h4>
                <h4>{data[0].value}</h4>
              </div>

              <StatusTasks>
                {data.slice(1).map((d) => (
                  <StatusTasksContainer
                    key={d.id}
                    backgroundColor={d.backgroundColor}
                  >
                    <h4 className="text">{d.title}</h4>
                    <div className="tag">
                      <h4>{d.value}</h4>
                    </div>
                  </StatusTasksContainer>
                ))}
              </StatusTasks>
            </div>
          </Card>
        </Link>
      </Tooltip>

      <Tooltip title="Ver tickets">
        {/* <Link to="/tickets"> */}
        <Card>
          <div className="header">
            <h3>Tickets</h3>

            <div className="icon">
              <SettingOutlined />
            </div>
          </div>

          <TicketsContainer>
            <div className="card">
              <div className="icon">
                <FileOutlined />
              </div>

              <div>
                <p>Total de Tickets</p>
                <h2>200</h2>
              </div>
            </div>

            <StatusTicketContainer>
              {tickets.slice(1).map((t) => (
                <StatusTasksContainer
                  backgroundColor={t.backgroundColor}
                  key={t.id}
                >
                  <h4>{t.title}</h4>
                  <div className="tag">{t.value}</div>
                </StatusTasksContainer>
              ))}
            </StatusTicketContainer>
          </TicketsContainer>
        </Card>
        {/* </Link> */}
      </Tooltip>

      <Tooltip title="Ver tickets">
        {/* <Link to="/tickets"> */}
        <Card>
          <div className="header">
            <h3>Colaboradores</h3>

            <div className="icon">
              <TeamOutlined />
            </div>
          </div>

          <ContributorsContainer>
            {contributors.map((c) => (
              <StatusTasksContainer
                backgroundColor={c.backgroundColor}
                key={c.id}
              >
                <h4>{c.title}</h4>
                <div className="tag">{c.value}</div>
              </StatusTasksContainer>
            ))}
          </ContributorsContainer>

          <RatesContainer>
            <RatesCard>
              <div className="rates">
                <h4>Avaliação Interna</h4>
                <div>
                  <h4>{mediaInternal && Number(mediaInternal).toFixed(2)}</h4>
                  <Rate allowHalf disabled value={mediaInternal} />
                </div>
              </div>
            </RatesCard>
            <RatesCard>
              <div className="rates">
                <h4>Avaliação Externa</h4>
                <div>
                  <h4>
                    {mediaEvaluation && Number(mediaEvaluation).toFixed(2)}
                  </h4>
                  <Rate allowHalf disabled value={mediaEvaluation} />
                </div>
              </div>
            </RatesCard>
          </RatesContainer>
        </Card>
      </Tooltip>
    </Container>
  )
}

export default CardsTasks
