export default {
  pt: {
    MaintenancePlanningTableTab: {
      planningTable: 'Tabela de Planejamento',
      fortnight: 'Quinzena',
      week: 'Semana',
      system: 'Sistema',
      component: 'Componente',
      activity: 'Atividades',
    },
  },
  en: {
    MaintenancePlanningTableTab: {
      planningTable: 'Planning Table',
      fortnight: 'Fortnight',
      week: 'Week',
      system: 'System',
      component: 'Component',
      activity: 'Activities',
    },
  },
  es: {
    MaintenancePlanningTableTab: {
      planningTable: 'Tabla de planificación',
      fortnight: 'Quincena',
      week: 'Semana',
      system: 'Sistema',
      component: 'Componente',
      activity: 'Actividades',
    },
  },
}
