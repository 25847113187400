/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'

import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Tag,
} from 'antd'
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { translate } from '~/utils/locale'
import SystemModalMaintenance from './Modal'
import { useActivity } from '~/hooks/Maintenance/Activity/useActivity'

import { Container } from './styles'
import HelpTooltip from '~/components/DataManipulation/Misc/HelpTooltip'

// import { Container } from './styles';

const CreateActivityComponent: React.FC = () => {
  const { createActivity, systemData } = useActivity()

  const [type, setType] = useState('')
  const [weekend, setWeekend] = useState('weekend')
  const [interval, setInterval] = useState<number | null>(null)

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']
    const regex = /^[0-9]*$/

    if (!regex.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault()
    }
  }

  const [form] = Form.useForm()

  const options = [
    { label: 'Somente durante a semana', value: 'days' },
    { label: 'Sábado e Domingo', value: 'weekend' },
    { label: 'Somente Sábado', value: 'saturday' },
    { label: 'Somente Domingo', value: 'sunday' },
  ]

  useEffect(() => {
    if (systemData?.id) {
      form.setFieldsValue({
        system: systemData?.name,
        component: systemData?.component,
      })
    }
  }, [form, systemData])

  function onCreateMaintenance(valueForm: any) {
    try {
      const formatData = {
        title: valueForm.title,
        system: valueForm.system,
        component: valueForm.component,
        activity: valueForm.activity,
        competence: valueForm.competence,
        data_font: valueForm.data_font,
        periodicity: {
          type: valueForm.type,
          interval: valueForm.interval,
          weekend: valueForm.weekend,
        },
      }

      createActivity(formatData)

      form.resetFields()
    } catch (err) {}
  }

  return (
    <Card
      title={
        <Space size="large">
          <Link to="/atividades">
            <ArrowLeftOutlined />
          </Link>
          <b>{translate('MaintenanceActivity.NewActivity')}</b>
        </Space>
      }
      extra={
        <Space size="middle">
          <Button
            type="primary"
            icon={<SaveOutlined />}
            htmlType="submit"
            form="maintenance"
            id="btn_create_maintenance"
          >
            {translate('MaintenanceCreate.Save')}
          </Button>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', padding: 30 }}
    >
      <Form
        onFinish={onCreateMaintenance}
        form={form}
        layout="vertical"
        id="maintenance"
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={translate('MaintenanceActivity.Title')}
              name="title"
            >
              <Input placeholder={translate('MaintenanceActivity.TitleP')} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={translate('MaintenanceCreate.DataSource')}
              name="data_font"
            >
              <Input
                placeholder={translate('MaintenanceCreate.EnterDataSource')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  <p>{translate('MaintenanceCreate.Component')}</p>

                  {systemData?.id ? (
                    <SystemModalMaintenance type="create" />
                  ) : (
                    ''
                  )}
                </Space>
              }
              name="component"
              rules={[
                {
                  required: true,
                  message: 'Informe o componente',
                },
              ]}
            >
              {systemData?.id ? (
                <Input />
              ) : (
                <SystemModalMaintenance type="create" />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('MaintenanceCreate.System')}
              name="system"
            >
              <Input
                placeholder={translate('SystemCreate.componentPlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  <p>Equipe/Competência</p>
                </Space>
              }
              name="competence"
            >
              <Input placeholder="Informe a equipe/competência" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('MaintenanceCreate.Activity')}
              name="activity"
            >
              <Input.TextArea
                placeholder={translate('MaintenanceCreate.AddActivity')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider>{translate('MaintenanceCreate.Periodicity')}</Divider>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  {translate('MaintenanceCreate.Type')}
                  <HelpTooltip title="Refere-se ao intervalo de repetição do evento ou ação." />
                </Space>
              }
              name="type"
            >
              <Select
                onChange={(e) => setType(e)}
                style={{ width: '100%' }}
                placeholder={translate('MaintenanceCreate.TypePlaceholder')}
              >
                <Select.Option value="day">
                  {translate('MaintenanceCreate.Daily')}
                </Select.Option>
                <Select.Option value="sem">
                  {translate('MaintenanceCreate.Weekly')}
                </Select.Option>
                <Select.Option value="men">
                  {translate('MaintenanceCreate.Monthly')}
                </Select.Option>
                <Select.Option value="anu">
                  {translate('MaintenanceCreate.Yearly')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <Space>
                  {translate('MaintenanceCreate.Interval')}
                  <HelpTooltip title="Representa o número de ciclos de frequência antes de cada repetição" />
                </Space>
              }
              name="interval"
            >
              <InputNumber
                onChange={(e: number | null) => setInterval(e)}
                onKeyPress={handleKeyPress}
                style={{ width: '100%' }}
                placeholder={translate('MaintenanceCreate.EnterInterval')}
              />
            </Form.Item>
          </Col>
        </Row>

        {type === 'day' && interval === 1 ? (
          <Row>
            <Form.Item label="Atividades aos Finais de Semana" name="weekend">
              <Radio.Group
                optionType="button"
                options={options}
                defaultValue="weekend"
                onChange={(value) => setWeekend(value.target.value)}
              />
            </Form.Item>
          </Row>
        ) : (
          ''
        )}

        {type && interval && (
          <Container>
            {type === 'day' ? (
              <>
                {interval === 1 ? (
                  <>
                    {weekend === 'weekend' ? (
                      <Tag className="tag" color="geekblue">
                        Atividade Diária - A cada {interval} dia e aos finais de
                        semana <br />
                      </Tag>
                    ) : (
                      ''
                    )}

                    {weekend === 'saturday' ? (
                      <Tag className="tag" color="geekblue">
                        Atividade Diária - A cada {interval} dia e aos sábados
                        <br />
                      </Tag>
                    ) : (
                      ''
                    )}

                    {weekend === 'sunday' ? (
                      <Tag className="tag" color="geekblue">
                        Atividade Diária - A cada {interval} dia e aos domingos
                        <br />
                      </Tag>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <Tag className="tag" color="geekblue">
                    Atividade Diária - A cada {interval} dias <br />
                  </Tag>
                )}
              </>
            ) : (
              ''
            )}

            {type === 'sem' ? (
              <>
                {interval === 1 ? (
                  <Tag className="tag" color="purple">
                    Atividade Semanal - A cada uma semana <br />
                  </Tag>
                ) : (
                  <Tag className="tag" color="purple">
                    Atividade Semanal - A cada {interval} semanas <br />
                  </Tag>
                )}
              </>
            ) : (
              ''
            )}

            {type === 'men' ? (
              <>
                {interval === 1 ? (
                  <Tag className="tag" color="green">
                    Atividade Mensal - A cada um mês <br />
                  </Tag>
                ) : (
                  <Tag className="tag" color="green">
                    Atividade Mensal - A cada {interval} meses <br />
                  </Tag>
                )}
              </>
            ) : (
              ''
            )}

            {type === 'anu' ? (
              <>
                {interval === 1 ? (
                  <Tag className="tag" color="magenta">
                    Atividade Anual - A cada um ano <br />
                  </Tag>
                ) : (
                  <Tag className="tag" color="magenta">
                    Atividade Anual - A cada {interval} anos <br />
                  </Tag>
                )}
              </>
            ) : (
              ''
            )}
          </Container>
        )}
      </Form>
    </Card>
  )
}

export default CreateActivityComponent
