export default {
  pt: {
    DashboardChartsTasksByValuePieChart: {
      filterText: 'Dados filtrados entre',
      cardTitle: 'Valor total por Tipos de Serviço',
      noResultText: 'Não existem tarefas nas datas selecionadas no filtro',
    },
  },
  en: {
    DashboardChartsTasksByValuePieChart: {
      filterText: 'Data filtered between',
      cardTitle: 'Total value by Task Types',
      noResultText: 'There are no tasks on the dates selected in the filter',
    },
  },
  es: {
    DashboardChartsTasksByValuePieChart: {
      filterText: 'Datos filtrados entre',
      cardTitle: 'Valor total por Tipos de Servicio',
      noResultText: 'No hay tareas en las fechas seleccionadas en el filtro',
    },
  },
}
