/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingOutlined } from '@ant-design/icons'
import { Card, DatePicker, Space, Table } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { LoadingIndicator } from '~/pages/Contributor/ShowContributor/styles'
import { translate } from '~/utils/locale'

import AddActivityModal from '../Activity/AddActivity'
import TaskInfoModal from './TaskInfoModal'
import Legend from './Legend'
import ViewActivity from '../Activity/View'

const PlanningMaintenanceTableTabs: React.FC = () => {
  const { isLoading, maintenance } = useShowMaintenance()
  const [selectedMonth, setSelectedMonth] = useState(dayjs())

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedTask, setSelectedTask] = useState<any>(null)
  const [organizedData, setOrganizedData] = useState<any | null>(null)

  const handleOpenModal = (selectedDate: dayjs.Dayjs, activityDetails: any) => {
    const tasksForSelectedDay = activityDetails.tasks.filter((task: any) => {
      const taskDate = dayjs(task.created).add(3, 'hour').startOf('day')
      return taskDate.isSame(selectedDate, 'day')
    })

    setSelectedTask({
      tasks: tasksForSelectedDay,
      activityDetails,
    })

    setIsModalVisible(true)
  }

  const calculateExpectedDates = (
    initialDate: string,
    periodicity: any,
    month: dayjs.Dayjs,
  ) => {
    const dates: string[] = []
    if (!initialDate || !periodicity) return dates

    const start = dayjs(initialDate).startOf('day')
    const startOfMonth = month.startOf('month')
    const endOfMonth = month.endOf('month')

    let current = start.isBefore(startOfMonth) ? start : startOfMonth

    while (current.isBefore(endOfMonth) || current.isSame(endOfMonth, 'day')) {
      if (current.isSame(start, 'day') || current.isAfter(start)) {
        if (periodicity.type === 'sem') {
          dates.push(current.toISOString())
          current = current.add(periodicity.interval * 7, 'day')
        } else if (periodicity.type === 'day') {
          const isWeekend =
            periodicity.weekend === 'weekend' ||
            (periodicity.weekend === 'saturday' && current.day() !== 0) ||
            (periodicity.weekend === 'sunday' && current.day() !== 6)

          const isWeekday =
            periodicity.weekend === 'days' &&
            current.day() >= 1 &&
            current.day() <= 5

          if (isWeekend || isWeekday) {
            dates.push(current.toISOString())
          }

          current = current.add(periodicity.interval, 'day')
        } else if (periodicity.type === 'men') {
          dates.push(current.toISOString())
          current = current.add(1, 'month')
        } else if (periodicity.type === 'anu') {
          dates.push(current.toISOString())
          current = current.add(1, 'year')
        }
      } else {
        current = current.add(1, 'day')
      }
    }

    return dates
  }

  const organizeMaintenanceData = (maintenance: any) => {
    const result: Record<string, Record<string, any[]>> = {}

    maintenance?.activities.forEach((activity: any) => {
      const { system, component, title, tasks } = activity

      const tasksFilter = tasks.filter((t: any) => {
        return t.plan === maintenance?._id
      })

      if (!result[system]) {
        result[system] = {}
      }

      if (!result[system][component]) {
        result[system][component] = []
      }

      result[system][component].push({
        _id: activity._id,
        createdAt: activity.createdAt,
        activityTitle: title,
        competence: activity.title,
        data_font: activity.data_font,
        initialDate: activity.initialDate,
        periodicity: activity.periodicity,
        system: activity.system,
        title: activity.title,
        updatedAt: activity.updatedAt,
        responsible: activity.responsible,
        activity: activity.activity,
        tasks: tasksFilter.map((task: any) => ({
          taskId: task.taskId,
          created: task.created,
          status: task.status,
          plan: task.plan,
        })),
      })
    })

    return result
  }

  useEffect(() => {
    if (maintenance) {
      const updatedData = organizeMaintenanceData(maintenance)

      Object.entries(updatedData).forEach(([, components]) => {
        Object.entries(components).forEach(([, activities]) => {
          activities.forEach((activity: any) => {
            activity.expectedDates =
              calculateExpectedDates(
                activity.initialDate,
                activity.periodicity,
                selectedMonth,
              ) || []
          })
        })
      })

      setOrganizedData(updatedData)
    }
  }, [maintenance, selectedMonth])

  const handleMonthChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setSelectedMonth(date)

      if (maintenance) {
        const updatedData = organizeMaintenanceData(maintenance)

        Object.entries(updatedData).forEach(([, components]) => {
          Object.entries(components).forEach(([, activities]) => {
            activities.forEach((activity: any) => {
              activity.expectedDates =
                calculateExpectedDates(
                  activity.initialDate,
                  activity.periodicity,
                  date,
                ) || []
            })
          })
        })

        setOrganizedData(updatedData)
      }
    }
  }

  const getStatusStyle = (status: string | undefined) => {
    switch (status) {
      case 'received':
        return {
          backgroundColor: '#fffb8f',
          border: '1px solid #d4b106',
          color: '#d4b106',
        }
      case 'viewed':
      case 'en-route':
      case 'working':
      case 'resume-working':
        return {
          backgroundColor: '#b7eb8f',
          border: '1px solid #389e0d',
          color: '#389e0d',
        }
      case 'break':
        return {
          backgroundColor: '#ffa39e',
          border: '1px solid #cf1322',
          color: '#cf1322',
        }
      case 'canceled':
        return {
          backgroundColor: 'gray',
          color: 'white',
        }
      case 'finished':
        return {
          backgroundColor: '#adc6ff',
          border: '1px solid #1d39c4',
          color: '#1d39c4',
        }
      default:
        return {
          backgroundColor: '#d3d3d3',
          border: '1px solid black',
          color: 'black',
        } // Cinza claro para valores undefined
    }
  }

  const calculateRowSpan = (data: any[], key: string) => {
    const rowSpanMap: Record<string, number[]> = {}
    data.forEach((row, index) => {
      const value = row[key]
      if (!rowSpanMap[value]) {
        rowSpanMap[value] = [index]
      } else {
        rowSpanMap[value].push(index)
      }
    })

    const rowSpanData: Record<number, number> = {}
    Object.values(rowSpanMap).forEach((indexes) => {
      indexes.forEach((index, i) => {
        rowSpanData[index] = i === 0 ? indexes.length : 0
      })
    })
    return rowSpanData
  }

  const generateCalendarColumns = () => {
    const daysInMonth = selectedMonth.daysInMonth()

    return [
      {
        title: (
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                width: '50%',
                display: 'inline-block',
                borderRight: '1px solid #f0f0f0',
              }}
            >
              {`${translate('MaintenancePlanningTableTab.fortnight')} 01`}
            </div>
            <div style={{ width: '50%', display: 'inline-block' }}>
              {`${translate('MaintenancePlanningTableTab.fortnight')} 02`}
            </div>
          </div>
        ),
        children: Array.from({ length: daysInMonth }, (_, i) => ({
          title: i + 1,
          dataIndex: `day_${i + 1}`,
          key: `day_${i + 1}`,
          width: 50,
          render: (value: string | null | undefined, row: any) => {
            if (!value) {
              return null
            }

            if (value === 'X') {
              return <span style={{ color: 'black' }}>X</span>
            }

            return (
              <div
                onClick={() => {
                  const selectedDate = selectedMonth
                    .set('date', i + 1)
                    .startOf('day')
                  handleOpenModal(selectedDate, row.activityDetails)
                }}
                style={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  ...getStatusStyle(value),
                }}
              />
            )
          },
        })),
      },
    ]
  }

  const fixedColumns = [
    {
      title: translate('MaintenancePlanningTableTab.system'),
      dataIndex: 'system',
      key: 'system',
      fixed: 'left' as const,
      width: 150,
      render: (value: string, _: any, index: number) => {
        const rowSpan = systemRowSpan[index]
        return {
          children: value,
          props: { rowSpan },
        }
      },
    },
    {
      title: translate('MaintenancePlanningTableTab.component'),
      dataIndex: 'component',
      key: 'component',
      fixed: 'left' as const,
      width: 200,
      render: (value: string, _: any, index: number) => {
        const rowSpan = componentRowSpan[index]
        return {
          children: value,
          props: { rowSpan },
        }
      },
    },
    {
      title: translate('MaintenancePlanningTableTab.activity'),
      dataIndex: 'activityDetails',
      key: 'activityDetails',
      fixed: 'left' as const,
      width: 200,
      render: (value: any) => (
        <ViewActivity activity={value} typeOfDisplay="text" />
      ),
    },
  ]

  const tableData: any[] = []

  if (organizedData) {
    Object.entries(organizedData).forEach(([system, components]: any) => {
      Object.entries(components).forEach(([component, activities]: any) => {
        activities?.forEach((activity: any, index: any) => {
          const daysInMonth = selectedMonth.daysInMonth()
          const row: any = {
            key: `${system}-${component}-${activity.activityTitle}-${index}`,
            system,
            component,
            activity: activity.activityTitle,
            activityDetails: {
              system,
              component,
              title: activity.activityTitle,
              competence: activity.competence,
              data_font: activity.data_font,
              initialDate: activity.initialDate,
              periodicity: activity.periodicity,
              responsible: activity.responsible,
              activity: activity.activity,
              tasks: activity.tasks,
              updatedAt: activity.updatedAt,
              _id: activity._id,
              createdAt: activity.createdAt,
            },
          }

          for (let day = 1; day <= daysInMonth; day++) {
            const date = selectedMonth.date(day).startOf('day')
            const isExpectedDate =
              Array.isArray(activity.expectedDates) &&
              activity.expectedDates.some((expectedDate: string) =>
                dayjs(expectedDate).isSame(date, 'day'),
              )

            const hasTask = activity.tasks.find((task: any) => {
              return dayjs(task.created).add(3, 'hour').isSame(date, 'day')
            })

            row[`day_${day}`] = hasTask
              ? hasTask.status
              : isExpectedDate
                ? 'X'
                : null
          }

          tableData.push(row)
        })
      })
    })
  }

  const systemRowSpan = calculateRowSpan(tableData, 'system')
  const componentRowSpan = calculateRowSpan(tableData, 'component')

  if (isLoading || !maintenance) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  return (
    <Card
      bordered={false}
      title={`${translate(
        'MaintenancePlanningTableTab.planningTable',
      )} - ${maintenance?.name}`}
      style={{
        display: 'inline-block',
        width: '100%',
      }}
      extra={
        <Space>
          <DatePicker
            picker="month"
            value={selectedMonth}
            onChange={handleMonthChange}
            format="MM/YYYY"
          />
          <AddActivityModal />
        </Space>
      }
    >
      <Legend getStatusStyle={getStatusStyle} />

      <TaskInfoModal
        selectedTask={selectedTask}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />

      <Table
        columns={[...fixedColumns, ...generateCalendarColumns()]}
        dataSource={tableData}
        scroll={{ x: 1500 }}
        bordered
        pagination={false}
      />
    </Card>
  )
}

export default PlanningMaintenanceTableTabs
