export default {
  pt: {
    DashboardChartsTasksByTypePieChart: {
      filterText: 'Dados filtrados entre',
      cardTitle: 'Tarefas por Tipos de Serviço',
      noResultText: 'Não existem tarefas nas datas selecionadas no filtro',
      tasksText: 'Tarefas',
    },
  },
  en: {
    DashboardChartsTasksByTypePieChart: {
      filterText: 'Data filtered between',
      cardTitle: 'Tasks by Tasks Types',
      noResultText: 'There are no tasks on the dates selected in the filter',
      tasksText: 'Tasks',
    },
  },
  es: {
    DashboardChartsTasksByTypePieChart: {
      filterText: 'Datos filtrados entre',
      cardTitle: 'Tareas por tipos de servicio',
      noResultText: 'No hay tareas en las fechas seleccionadas en el filtro',
      tasksText: 'Tareas',
    },
  },
}
