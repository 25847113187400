/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useState, useCallback } from 'react'
import { useQuery } from 'react-query'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'
import { useParams } from 'react-router-dom'

interface ActivityProps {
  _id: string
  title: string
  createdAt: string
  component?: string
  activity?: string
  responsibles?: [{ name: string; _id: string }]
  periodicity: {
    type: string
    interval: number
    date: Date
    weekend: string
  }
  initialDate: Date
  responsible: string
  data_font: string
  tasks: [
    {
      taskId: string
      created: Date
    },
  ]
}

interface MaintenanceShowProps {
  _id: string
  company_id: string
  name?: string
  customer?: {
    id: string
    name: string
    address?: {
      zip_code: string
      street: string
      number: string
      complement: string
      neighborhood: string
      city: string
      state: string
      location: { x: number; y: number }
    }
  }
  description?: string
  future_days?: number
  responsible: {
    name: string
    phone: string
  }
  documents: [
    {
      _id: string
      name: string
      provided_responsible: string
      renovation_responsible: string
      inclusion_date: Date
      renovation_date: Date
      attchment: string
    },
  ]
  activities: ActivityProps[]
}

interface FilterOptionsProps {
  name?: string
  provided_responsible?: string
  renovation_responsible?: string
  inclusion_date?: Date
  renovation_date?: Date
}

interface MaintenanceShowContextData {
  maintenance?: MaintenanceShowProps
  updateMaintenance(valueForm: object | undefined): void
  addActivity(valueForm: object | undefined): void
  updateActivity(
    valueForm: object | undefined,
    systemId?: string,
    activityId?: string,
  ): void
  deleteActivity(activityId?: string): void
  addDocument(valueForm: object | undefined): void
  updateDocuments(valueForm: object | undefined, document_id: string): void
  deleteDocument(documentId?: string): void
  isLoading: boolean
  refetch: () => void
  isFetching: boolean
  setIsEdit(value: boolean): void
  isEdit?: boolean
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
}

const MaintenanceShowContext = createContext<MaintenanceShowContextData>(
  {} as MaintenanceShowContextData,
)

export const MaintenanceShowProvider: React.FC = ({ children }) => {
  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:documentsMainFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const { maintenanceId } = useParams<{ maintenanceId: string }>()

  const [isEdit, setIsEdit] = useState<boolean>()

  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()

  const {
    data: maintenance,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [`sistemas/gestaoShow${selectedCompany}`, selectedCompany, filterOptions],

    async () => {
      try {
        const response = await api.get(
          `/company/${selectedCompany}/maintenance/${maintenanceId}`,
          {
            params: {
              name: filterOptions?.name,
              provided_responsible: filterOptions?.provided_responsible,
              renovation_responsible: filterOptions?.renovation_responsible,
            },
          },
        )

        const { data } = response

        return data
      } catch (err: any) {
        ShowError(err.message, translate('useSystem.getErrorMessage'), locale)
      }
    },
  )

  async function updateMaintenance(valueForm: object) {
    try {
      await api.put(
        `/company/${selectedCompany}/maintenance/${maintenanceId}`,
        valueForm,
      )

      message.success(translate('useMaintenance.MaintenanceUpdatedSuccess'))
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.MaintenanceUpdatedError'),
        locale,
      )
    }
  }

  async function addActivity(form?: object) {
    try {
      await api.post(
        `/company/${selectedCompany}/maintenance/${maintenanceId}/activity`,
        form,
      )

      refetch()
      message.success(translate('useMaintenance.ActivityAddedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.ActivityAddedError'),
        locale,
      )
    }
  }

  async function updateActivity(
    form?: object,
    systemId?: string,
    activityId?: string,
  ) {
    try {
      await api.put(
        `/company/${selectedCompany}/maintenance/${maintenanceId}/system/${systemId}/activity/${activityId}`,
        { updatedActivity: form },
      )

      refetch()
      message.success(translate('useMaintenance.ActivityUpdatedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.ActivityUpdatedError'),
        locale,
      )
    }
  }

  async function deleteActivity(activityId?: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/maintenance/${maintenanceId}/activity/${activityId}`,
      )

      refetch()
      message.success(translate('useMaintenance.ActivityDeletedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.ActivityDeletedError'),
        locale,
      )
    }
  }

  async function addDocument(form?: object) {
    try {
      await api.post(
        `/company/${selectedCompany}/maintenance/${maintenanceId}/documents`,
        form,
      )

      refetch()
      message.success(translate('useMaintenance.DocumentCreatedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.DocumentCreatedError'),
        locale,
      )
    }
  }

  async function updateDocuments(valueForm: object, documentId: string) {
    try {
      await api.put(
        `/company/${selectedCompany}/maintenance/${maintenanceId}/document/${documentId}`,
        valueForm,
      )

      message.success('Documento atualizado com sucesso!')
      refetch()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.MaintenanceUpdatedError'),
        locale,
      )
    }
  }

  async function deleteDocument(documentId?: string) {
    try {
      await api.delete(
        `/company/${selectedCompany}/maintenance/${maintenanceId}/document/${documentId}`,
      )

      refetch()
      message.success(translate('useMaintenance.DocumentDeletedSuccess'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useMaintenance.DocumentDeletedError'),
        locale,
      )
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      name: newFilterOptions?.name,
      provided_responsible: newFilterOptions?.provided_responsible,
      renovation_responsible: newFilterOptions?.renovation_responsible,
      inclusion_date: newFilterOptions?.inclusion_date,
      renovation_date: newFilterOptions?.renovation_date,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:documentsFilterOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:documentsMainFilterOptions')
    setFilterOptions({})
  }, [])

  return (
    <MaintenanceShowContext.Provider
      value={{
        maintenance,
        updateMaintenance,
        addActivity,
        updateActivity,
        deleteActivity,
        addDocument,
        updateDocuments,
        deleteDocument,
        isLoading,
        refetch,
        isFetching,
        isEdit,
        setIsEdit,
        handleUpdateFilters,
        handleResetFilters,
        filterOptions,
      }}
    >
      {children}
    </MaintenanceShowContext.Provider>
  )
}

export function useShowMaintenance(): MaintenanceShowContextData {
  const context = useContext(MaintenanceShowContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
