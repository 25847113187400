/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'

import {
  Form,
  Modal,
  Select,
  Button,
  Input,
  Upload,
  Radio,
  Tooltip,
} from 'antd'
import { EditOutlined, InboxOutlined } from '@ant-design/icons'
import { useTask } from '~/hooks/Tasks/useTask'
import { translate } from '~/utils/locale'
import { useContributorsTaskFilter } from '~/hooks/Tasks/useListContributorsTask'
import { ContainerButton } from '../Create/styles'

interface NewAttachmentData {
  created_at: Date
  id: string
  type: string
  value: string
  title: string
  contributor: string
  from: string
  file_url: string
}

interface ReportData {
  data: NewAttachmentData
}

// import { Container } from './styles';

const UpdateReport: React.FC<ReportData> = ({ data }) => {
  const { updateReport } = useTask()
  const [typeReport, setTypeReport] = useState<string>(
    data.type === 'notes' ? 'notes' : 'archive',
  )

  const [fileList] = useState<any[]>([
    {
      uid: '-1', // UID único
      name: data.title, // Nome do arquivo
      status: 'done', // Exibe como já enviado
      url: data.file_url, // URL do arquivo enviado
    },
  ])

  const { contributorsTaskFilter } = useContributorsTaskFilter()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [attachment, setAttachment] = useState<File>({} as File)

  const [form] = Form.useForm()

  const { Option } = Select

  function handleTypeReport(value: string) {
    setTypeReport(value)
  }

  const props = {
    maxCount: 1,
    onRemove: () => {
      setAttachment({} as File)
    },
    beforeUpload: (file: any) => {
      setAttachment(file)
      return false
    },
    attachment,
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const initialValues = {
    contributor: data.contributor,
    title: data.title,
    from: data.from,
    value: data.value,
  }

  const typeReports = data.type === 'notes' ? 'notes' : 'archive'

  function onUpdateReport(dataForm: NewAttachmentData) {
    try {
      setConfirmLoading(true)

      if (typeReport === 'archive') {
        const dados = new FormData()
        const attachmentType = attachment.type.split('/')
        dados.append('value', attachment)
        dados.append('type', attachmentType[0])
        dados.append('title', dataForm.title)
        dados.append('contributor', dataForm.contributor)
        dados.append('from', dataForm.from)
        console.log(attachment)
        updateReport(dados, data.id)
      } else {
        const dados = {
          type: data.type,
          value: dataForm.value,
          title: dataForm.title,
          contributor: dataForm.contributor,
          from: dataForm.from,
        }

        updateReport(dados, data.id)
      }

      form.resetFields()
      setConfirmLoading(false)

      setIsModalVisible(false)
    } catch (error) {
      setConfirmLoading(false)
    }
  }

  console.log(data)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    form.resetFields()
    setIsModalVisible(false)
  }
  return (
    <>
      <Tooltip title="Editar Evidência">
        <Button shape="circle" icon={<EditOutlined />} onClick={showModal} />
      </Tooltip>
      <Modal
        title={translate('TasksTabsReportCreate.modalTitle')}
        open={isModalVisible}
        confirmLoading={confirmLoading}
        onCancel={closeModal}
        width="600px"
        style={{
          top: 50,
          maxHeight: 'calc(100vh - 200px)',
        }}
        footer={[
          <ContainerButton key="buttons">
            <Button
              key="save"
              type="primary"
              htmlType="submit"
              form="updateReportsTask"
              id="btn_update_report_task"
            >
              {translate('TasksTabsReportCreate.okText')}
            </Button>
          </ContainerButton>,
        ]}
      >
        <>
          <Form.Item label={translate('TasksTabsReportCreate.formatLabel')}>
            <Select
              placeholder={translate('TasksTabsReportCreate.formatPlaceholder')}
              onChange={handleTypeReport}
              defaultValue={typeReports}
            >
              <Option value="notes">
                {translate('TasksTabsReportCreate.formatNotes')}
              </Option>
              <Option value="archive">
                {translate('TasksTabsReportCreate.formatArchive')}
              </Option>
            </Select>
          </Form.Item>

          {typeReport === 'notes' ? (
            <Form
              layout="vertical"
              onFinish={onUpdateReport}
              form={form}
              id="updateReportsTask"
              initialValues={initialValues}
            >
              <Form.Item
                label="Colaborador que está enviado a evidência"
                name="contributor"
              >
                <Select
                  optionFilterProp="children"
                  showSearch
                  placeholder="Selecione o colabordor"
                >
                  {contributorsTaskFilter?.map((contribuitor) => (
                    <Option
                      key={contribuitor.id}
                      value={contribuitor.contact.name}
                    >
                      {contribuitor.contact.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={translate('TasksTabsReportCreate.titleLabel')}
                name="title"
              >
                <Input
                  placeholder={translate(
                    'TasksTabsReportCreate.titlePlaceholder',
                  )}
                />
              </Form.Item>

              <Form.Item
                label={translate('TasksTabsReportCreate.reportLabel')}
                name="value"
              >
                <Input.TextArea />
              </Form.Item>

              <Form.Item label="Evidência enviada por:" name="from">
                <Radio.Group>
                  <Radio value="desktop">Desktop</Radio>
                  <Radio value="mobile">Mobile</Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          ) : (
            ''
          )}

          {typeReport === 'archive' ? (
            <Form
              layout="vertical"
              onFinish={onUpdateReport}
              form={form}
              id="updateReportsTask"
              initialValues={initialValues}
            >
              <Form.Item
                label="Colaborador que está enviado a evidência"
                name="contributor"
              >
                <Select
                  optionFilterProp="children"
                  showSearch
                  placeholder="Selecione o colabordor"
                >
                  {contributorsTaskFilter?.map((contribuitor) => (
                    <Option
                      key={contribuitor.id}
                      value={contribuitor.contact.name}
                    >
                      {contribuitor.contact.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={translate('TasksTabsReportCreate.titleLabel')}
                name="title"
              >
                <Input
                  placeholder={translate(
                    'TasksTabsReportCreate.titlePlaceholder',
                  )}
                />
              </Form.Item>
              <Form.Item label="Evidência enviada por:" name="from">
                <Radio.Group>
                  <Radio value="desktop">Desktop</Radio>
                  <Radio value="mobile">Mobile</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="dragger"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: translate('TasksTabsReportCreate.draggerRule'),
                  },
                ]}
              >
                <Upload.Dragger
                  name="files"
                  {...props}
                  fileList={fileList}
                  listType="picture"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    {translate('TasksTabsReportCreate.draggerParagraphOne')}
                  </p>
                  <p className="ant-upload-hint">
                    {translate('TasksTabsReportCreate.draggerParagraphTwo')}
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form>
          ) : (
            ''
          )}
        </>
      </Modal>
    </>
  )
}

export default UpdateReport
