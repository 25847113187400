export default {
  pt: {
    DashboardTasksInitial: {
      totalTasks: 'Total de Tarefas',
      totalTickets: 'Total de Tickets',
      viewedTasks: 'Visualizadas',
      onTheWayTasks: 'A Caminho',
      finishedTasks: 'Finalizadas',
      filterText: 'Informações referentes ao periodo total',
    },
  },
  en: {
    DashboardTasksInitial: {
      totalTasks: 'Total Tasks',
      totalTickets: 'Total Tickets',
      viewedTasks: 'Viewed',
      onTheWayTasks: 'On the way',
      finishedTasks: 'Completed',
      filterText: 'Information regarding the total period',
    },
  },
  es: {
    DashboardTasksInitial: {
      totalTasks: 'Tareas totales',
      totalTickets: 'Entradas totales',
      viewedTasks: 'Vistas',
      onTheWayTasks: 'En camino',
      finishedTasks: 'Completadas',
      filterText: 'Información sobre el periodo total',
    },
  },
}
