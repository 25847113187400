/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-param-reassign */
import { PlusCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
} from 'antd'
import { decode } from 'base-64'
import React from 'react'

import PhoneInputWithCountrySelect from 'react-phone-number-input'

import pt_BR from 'react-phone-number-input/locale/pt-BR.json'
import { useParams } from 'react-router-dom'

import { useLocale } from '~/hooks/locale/useLocale'

import api from '~/services/api'
import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface TicketsData {
  title: string
  request_type_id: string
  customer: string
  customer_phone: string
  customer_email: string
  priority: string
  description: string
  id: string
  date: string
}

const CreateTicketCustomer: React.FC = () => {
  const { companyId, clientId, emailId, phoneId, companyName } = useParams<{
    companyId: string
    clientId: string
    emailId: string
    phoneId: string
    companyName: string
  }>()

  const decodeClient = decode(clientId)
  const decodeEmail = decode(emailId)
  const decodePhone = decode(phoneId)
  const decodeCompanyName = decode(companyName)

  const [form] = Form.useForm()
  const { locale } = useLocale()

  const initialValues = {
    customer:
      decodeClient !== 'null' ? decodeClient : 'Nenhum cliente informado.',
    customer_email:
      decodeEmail !== 'null' ? decodeEmail : 'Nenhum email informado.',
    customer_phone: decodePhone !== 'null' ? decodePhone : '',
  }

  async function onCreateTicketsCustomers(dataForm: TicketsData) {
    try {
      if (dataForm.customer_phone) {
        dataForm.customer_phone = dataForm.customer_phone.replace(/[^0-9]/g, '')
      }

      const ticketsData = {
        title: dataForm.title,
        customer: dataForm.customer,
        customer_email: dataForm.customer_email,
        customer_phone: dataForm.customer_phone,
        priority: dataForm.priority,
        description: dataForm.description,
        date: dataForm.date,
      }

      await api.post(`/company/${companyId}/ticket/customer`, ticketsData)
      message.success(translate('useListTickets.createSuccessMessage'))

      form.resetFields()
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListTickets.createErrorMessage'),
        locale,
      )
    }
  }

  return (
    <>
      <h1>
        Helpdesk - &nbsp;
        {decodeCompanyName}
      </h1>
      <h2>Novo Ticket</h2>
      <p>Preencha as informações e crie um novo ticket!</p>

      <Form
        id="newCreateTicketsCustomer"
        layout="vertical"
        onFinish={onCreateTicketsCustomers}
        form={form}
        style={{ width: '100%', marginTop: 20 }}
        initialValues={initialValues}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={translate('HelpDeskCreate.titleLabel')}
              name="title"
              rules={[
                {
                  required: true,
                  message: translate('HelpDeskCreate.titleRule'),
                },
              ]}
            >
              <Input
                placeholder={translate('HelpDeskCreate.titlePlaceholder')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('HelpDeskCreate.clientLabel')}
              name="customer"
              rules={[
                {
                  required: true,
                  message: translate('HelpDeskCreate.clientRule'),
                },
              ]}
            >
              <Input
                placeholder={translate('HelpDeskCreate.clientPlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={translate('HelpDeskCreate.emailClientLabel')}
              name="customer_email"
              rules={[
                {
                  required: true,
                  message: translate('HelpDeskCreate.emailClientRule'),
                },
              ]}
            >
              <Input
                placeholder={translate('HelpDeskCreate.emailClientPlaceholder')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate('HelpDeskCreate.phoneClientLabel')}
              name="customer_phone"
              rules={[
                {
                  required: true,
                  message: 'Informe o telefone.',
                },
              ]}
            >
              <PhoneInputWithCountrySelect
                placeholder={translate('HelpDeskCreate.phoneClientPlaceholder')}
                labels={pt_BR}
                defaultCountry="BR"
                international
                onChange={() => {}}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={translate('HelpDeskCreate.priorityLabel')}
              name="priority"
              rules={[
                {
                  required: true,
                  message: translate('HelpDeskCreate.priorityRule'),
                },
              ]}
            >
              <Select
                placeholder={translate(
                  'TasksCreateDetails.priorityPlaceholder',
                )}
              >
                <Select.Option value="high">
                  {translate('TasksCreateDetails.priorityHigh')}
                </Select.Option>
                <Select.Option value="medium">
                  {translate('TasksCreateDetails.priorityMedium')}
                </Select.Option>
                <Select.Option value="low">
                  {translate('TasksCreateDetails.priorityLow')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={translate('HelpDeskCreate.dateLabel')}
              name="date"
              rules={[
                {
                  required: true,
                  message: translate('HelpDeskCreate.dateRules'),
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY HH:mm"
                style={{ width: '100%' }}
                placeholder={translate('HelpDeskCreate.datePlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={translate('HelpDeskCreate.descriptionLabel')}
          name="description"
        >
          <Input.TextArea
            placeholder={translate('HelpDeskCreate.descriptionPlaceholder')}
          />
        </Form.Item>

        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          form="newCreateTicketsCustomer"
          htmlType="submit"
        >
          {translate('HelpDeskCreate.registerButton')}
        </Button>
      </Form>
    </>
  )
}

export default CreateTicketCustomer
