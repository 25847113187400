import React from 'react'

import { Tabs, Button } from 'antd'
import { PageHeader } from '@ant-design/pro-components'

import { EditOutlined } from '@ant-design/icons'
import { translate } from '~/utils/locale'
import DetailsTicket from './Details'
import { useTicket } from '~/hooks/HelpDesk/useTickets'
import UpdateTicket from '../Update'
import TasksTickets from './Tasks'

import { Container } from './styles'
import { useHistory, useParams } from 'react-router-dom'

const HelpDeskTabs: React.FC = () => {
  const { isEdit, setIsEdit, ticket } = useTicket()

  const { TabPane } = Tabs

  const { ticketId, tab } = useParams<{ ticketId: string; tab: string }>()

  const history = useHistory()

  const handleTabChange = (key: string) => {
    if (key === 'detalhes') {
      history.replace({
        pathname: `/ticket/${ticketId}`,
      })
    } else {
      history.replace({
        pathname: `/ticket/${ticketId}/${key}`,
      })
    }
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={
          !isEdit
            ? translate('HelpDeskTabs.viewTicketTitle')
            : translate('HelpDeskTabs.editTicketTitle')
        }
        subTitle={ticket?.title}
      />
      <Tabs
        onTabClick={(key: string) => handleTabChange(key)}
        defaultActiveKey={tab ? `${tab}` : 'detalhes'}
        tabBarExtraContent={
          <Container>
            <Button
              id="btn-edit-ticket"
              type="primary"
              icon={<EditOutlined />}
              onClick={() => setIsEdit(true)}
            >
              {translate('TasksTabsDetails.editButton')}
            </Button>
          </Container>
        }
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <TabPane tab={translate('HelpDeskTabs.detailsTab')} key="detalhes">
          {!isEdit ? <DetailsTicket /> : <UpdateTicket />}
        </TabPane>
        <TabPane tab={translate('HelpDeskTabs.tasksTab')} key="tarefas">
          <TasksTickets />
        </TabPane>
      </Tabs>
    </>
  )
}

export default HelpDeskTabs
