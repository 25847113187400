export default {
  pt: {
    DashboardTasksMapsTaks: {
      filterText: 'Tarefas filtradas no período de',
    },
  },
  en: {
    DashboardTasksMapsTaks: {
      filterText: 'Tasks filtered in the period',
    },
  },
  es: {
    DashboardTasksMapsTaks: {
      filterText: 'Tareas filtradas en el periodo',
    },
  },
}
