/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

import { Popconfirm, Space, Table, Tooltip, Button, message } from 'antd'
import {
  CopyOutlined,
  DeleteFilled,
  FileUnknownOutlined,
  LoadingOutlined,
  ProfileOutlined,
} from '@ant-design/icons'

import Text from 'antd/lib/typography/Text'

import { translate } from '~/utils/locale'
import { useMaintenance } from '~/hooks/Maintenance/useMaintenance'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'

// import { Container } from './styles';

interface MaintenanceProps {
  _id: string
  company_id: string
  customer?: string
  name?: string
  responsible: {
    name: string
    phone: string
  }
  activity: {
    _id: string
    title: string
  }
  documents: {
    name: string
  }
}

const MaintenanceTable: React.FC = () => {
  const { maintenance, deleteMaintenance, isLoading } = useMaintenance()

  const handleCopy = (id: string) => {
    navigator.clipboard.writeText(id).then(() => {
      message.success(translate('ContributorTable.copyText'))
    })
  }

  const columns: ColumnsType<MaintenanceProps> = [
    {
      title: 'ID Plano',
      align: 'left',
      dataIndex: '_id',
      key: 'id',
      // defaultSortOrder: 'ascend',
      // sortDirections: ['ascend', 'descend', 'ascend'],
      // sorter: (a, b) => a.contact.name.localeCompare(b.contact.name),
      render: (_id: string) => (
        <Tooltip title={_id}>
          <Button
            type="link"
            icon={<CopyOutlined />}
            onClick={() => handleCopy(_id)}
            style={{ padding: 0, height: 'auto' }}
          >
            <Text ellipsis>{`${_id.substring(0, 8)}...`}</Text>
          </Button>
        </Tooltip>
      ),
    },
    {
      title: 'Nome do Plano',
      key: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <Link to={`/manutencao/visualizar/${record._id}`}>{record.name}</Link>
      ),
    },
    {
      title: translate('MaintenanceTable.clientColumn'),
      key: 'client',
      dataIndex: ['customer', 'name'],
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (customer: string) => (
        <>
          {customer || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('MaintenanceTable.responsibleColumn'),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: MaintenanceProps) => (
        <>
          {record?.responsible?.name ? (
            <Text>{record?.responsible?.name}</Text>
          ) : (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('MaintenanceTable.responsiblePhoneColumn'),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: MaintenanceProps) => (
        <>
          {record?.responsible?.phone ? (
            <Text>
              <NumberFormat
                value={record?.responsible?.phone}
                displayType="text"
                format="+## (##) #####-####"
              />
            </Text>
          ) : (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('MaintenanceTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('MaintenanceTable.visualizationButon')}>
            <Link to={`/manutencao/visualizar/${record._id}`}>
              <Button
                ghost
                type="primary"
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>
          <Popconfirm
            title={translate('MaintenanceTable.popConfirmText')}
            onConfirm={() => deleteMaintenance(record._id)}
          >
            <Tooltip title={translate('MaintenanceTable.deleteButton')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Table
        rowKey={(record) => record._id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
          size: 'large',
        }}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={maintenance}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </>
  )
}

export default MaintenanceTable
