export default {
  pt: {
    DashboardChartsTasksValueLineChart: {
      filterText: 'Dados filtrados entre',
      customTooltipText: 'Valor Total das Tarefas',
      cardTitle: 'Valor Total das Tarefas nos Últimos dias',
      noResultText: 'Não existem tarefas nas datas selecionadas no filtro',
    },
  },
  en: {
    DashboardChartsTasksValueLineChart: {
      filterText: 'Data filtered between',
      customTooltipText: 'Total Value of Tasks',
      cardTitle: 'Total Value of Tasks in the Last Days',
      noResultText: 'There are no tasks on the dates selected in the filter',
    },
  },
  es: {
    DashboardChartsTasksValueLineChart: {
      filterText: 'Datos filtrados entre',
      customTooltipText: 'Valor total de las tareas',
      cardTitle: 'Valor total de las tareas en los últimos días',
      noResultText: 'No hay tareas en las fechas seleccionadas en el filtro',
    },
  },
}
