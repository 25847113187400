export default {
  pt: {
    MaintenancePlanningTableInfoModal: {
      statusReceived: 'Tarefa Enviada',
      statusViewed: 'Tarefa Visualizada',
      statusEnRoute: 'Tarefa a caminho',
      statusWorking: 'Tarefa sendo realizada',
      statusResumeWorking: 'Tarefa Retomada',
      statusBreak: 'Tarefa Pausada',
      statusCanceled: 'Tarefa Cancelada',
      statusFinished: 'Tarefa Finalizada',
      modalTitle: 'Detalhes da Tarefa',
      taskIdText: 'ID da tarefa',
      tooltipText: 'Ver tarefa',
      statusText: 'Status',
      statusUndefined: 'Status não definido',
      taskDateText: 'Data da Tarefa',
      noDateText: 'Nenhuma data informada.',
      planText: 'Plano',
      noPlanText: 'Nenhum plano informado',
    },
  },
  en: {
    MaintenancePlanningTableInfoModal: {
      statusReceived: 'Task Submitted',
      statusViewed: 'Task Viewed',
      statusEnRoute: 'Task on the way',
      statusWorking: 'Task being performed',
      statusResumeWorking: 'Resume Task',
      statusBreak: 'Paused Task',
      statusCanceled: 'Canceled Task',
      statusFinished: 'Finished Task',
      modalTitle: 'Task Details',
      taskIdText: 'Task ID',
      tooltipText: 'View Task',
      statusText: 'Status',
      statusUndefined: 'Status not defined',
      taskDateText: 'Task Date',
      noDateText: 'No date given.',
      planText: 'Plan',
      noPlanText: 'No plans reported',
    },
  },
  es: {
    MaintenancePlanningTableInfoModal: {
      statusReceived: 'Tarea Enviada',
      statusViewed: 'Tarea Vista',
      statusEnRoute: 'Tarea en camino',
      statusWorking: 'Tarea que se está realizando',
      statusResumeWorking: 'Reanudar Tarea',
      statusBreak: 'Tarea en pausa',
      statusCanceled: 'Tarea cancelada',
      statusFinished: 'Tarea terminada',
      modalTitle: 'Detalles de la tarea',
      taskIdText: 'ID de tarea',
      tooltipText: 'Ver tarea',
      statusText: 'Estado',
      statusUndefined: 'Estado no definido',
      taskDateText: 'Fecha de la tarea',
      noDateText: 'No se ha dado fecha.',
      planText: 'Plane',
      noPlanText: 'No se reportaron planes',
    },
  },
}
