export default {
  pt: {
    DashboardContributorsCards: {
      contributorsTotal: 'Total de Colaboradores',
      enabledText: 'Habilitados',
      enabledWithRestrictionsText: 'Habilitados com Restrições',
      blockedText: 'Bloqueados',
      contributorsText: 'Colaboradores',
    },
  },
  en: {
    DashboardContributorsCards: {
      contributorsTotal: 'Total Collaborators',
      enabledText: 'Enabled',
      enabledWithRestrictionsText: 'Enabled with Restrictions',
      blockedText: 'Blocked',
      contributorsText: 'Collaborators',
    },
  },
  es: {
    DashboardContributorsCards: {
      contributorsTotal: 'Colaboradores totales',
      enabledText: 'Activado',
      enabledWithRestrictionsText: 'Habilitado con restricciones',
      blockedText: 'Obstruido',
      contributorsText: 'Colaboradores',
    },
  },
}
