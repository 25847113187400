export default {
  pt: {
    ContributorTable: {
      copyText: 'ID copiado para a área de transferência!',
      nameColumn: 'Nome',
      contactColumn: 'Contato',
      skillsColumn: 'Habilidades',
      statusColumn: 'Status',
      noStatusText: 'Nenhum status encontrado.',
      actionsColumn: 'Ações',
      viewTooltip: 'Visualizar Colaborador',
      deletePopconfirm: 'Deseja mesmo deletar o colaborador?',
      deleteTooltip: 'Deletar Colaborador',
    },
  },
  en: {
    ContributorTable: {
      copyText: 'ID copied to clipboard!',
      nameColumn: 'Name',
      contactColumn: 'Contact',
      skillsColumn: 'Skills',
      statusColumn: 'Status',
      noStatusText: 'No status found.',
      actionsColumn: 'Actions',
      viewTooltip: 'View Collaborator',
      deletePopconfirm: 'Do you really want to delete the collaborator?',
      deleteTooltip: 'Delete Collaborator',
    },
  },
  es: {
    ContributorTable: {
      copyText: '¡ID copiada al portapapeles!',
      nameColumn: 'Nombre',
      contactColumn: 'Contacto',
      skillsColumn: 'Habilidades',
      statusColumn: 'Status',
      noStatusText: 'no se encontró ningún status.',
      actionsColumn: 'Acciones',
      viewTooltip: 'Ver Colaborador',
      deletePopconfirm: '¿Realmente quieres eliminar al colaborador?',
      deleteTooltip: 'Eliminar colaborador',
    },
  },
}
