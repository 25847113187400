import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Select, Space, Tooltip } from 'antd'
import Text from 'antd/lib/typography/Text'

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useListTask } from '~/hooks/Tasks/useListTasks'
import { translate } from '~/utils/locale'
import ConfigurationsKanban from '../../Options/Kanban/Configurations'
import FilterTasks from '../../Options/Kanban/Filter'

import ListKanban from '../../Options/Kanban/List'
import MoreOptions from '~/components/DataManipulation/Misc/MoreOptions'

// import { Container } from './styles';

const KanbanBoard: React.FC = () => {
  const { isLoading, isFetching, tasksTotal } = useListTask()

  const defaultVisualization = 'complete'

  const [visualization, setVisualization] = useState(
    localStorage.getItem('visualizationKanban') || defaultVisualization,
  )

  const handleVisualization = (value: string) => {
    setVisualization(value)
    localStorage.setItem('visualizationKanban', value)
  }

  useEffect(() => {
    if (!localStorage.getItem('visualization')) {
      localStorage.setItem('visualization', defaultVisualization)
    }
  }, [])

  return (
    <Card
      title={
        <Space size="large">
          <b>{translate('TasksBoardsKanban.cardTitle')}</b>
          {isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <Text
              type="secondary"
              style={{ fontWeight: 'normal', fontSize: 14 }}
            >
              {tasksTotal !== undefined ? (
                <>
                  {tasksTotal === 1
                    ? `${translate(
                        'TasksBoardsKanban.totalText',
                      )} ${tasksTotal} ${translate(
                        'TasksBoardsKanban.taskText',
                      )}`
                    : `${translate(
                        'TasksBoardsKanban.totalText',
                      )} ${tasksTotal} ${translate(
                        'TasksBoardsKanban.tasksText',
                      )}`}
                </>
              ) : (
                ''
              )}
            </Text>
          )}
          {!isLoading && isFetching && <LoadingOutlined spin />}
        </Space>
      }
      extra={
        <Space size="middle">
          <Tooltip title={translate('TasksBoardsList.exportTooltip')}>
            <Link to="/relatorioTarefas">
              <Button icon={<DownloadOutlined />} shape="circle" />
            </Link>
          </Tooltip>

          <MoreOptions
            exportTooltip={translate('TasksBoardsList.exportTooltip')}
            importTooltip={translate('TasksBoardsList.importTooltip')}
            modalTitle={translate('TasksBoardsList.modalTitle')}
            importTitle={translate('TasksBoardsList.importTitle')}
            importDescription={translate('TasksBoardsList.importDescription')}
            modelURL="/ModelDownload/tasks.xlsx"
            uploadRoute="tasks/import"
            uploadFileName="tasks"
          />

          <Select value={visualization} onChange={handleVisualization}>
            <Select.Option value="complete">
              {translate('TasksBoardsKanban.completeOption')}
            </Select.Option>
            <Select.Option value="resume">
              {translate('TasksBoardsKanban.resumeOption')}
            </Select.Option>
          </Select>

          <ConfigurationsKanban />
          <FilterTasks />

          <Link to="/novaTarefa">
            <Button type="primary">
              {translate('TasksBoardsKanban.createButton')}
            </Button>
          </Link>
        </Space>
      }
      bordered={false}
      style={{ width: '100%', height: '100%' }}
    >
      <ListKanban />
    </Card>
  )
}

export default KanbanBoard
