export default {
  pt: {
    HelpDesk: {
      cardTitle: 'Tickets',
      totalText: 'Total de',
      breakReasonText: 'ticket',
      breakReasonsText: 'tickets',
      shareLink: 'Compartilhar',
      shareText:
        'Compartilhar link com cliente para a criação de um novo ticket.',
      buttonText: 'Novo ticket',
      linkText: 'Compartilhar Link - Helpdesk',
      clientText:
        'Escolha para qual cliente será gerado o link para abertura de tickets de solicitações.',
      clientTextTooltip:
        'Utilizando este link, o cliente poderá registrar por conta própria solicitações no sistema.',
      buttonLink: 'Gerar Link',
    },
  },
  en: {
    HelpDesk: {
      cardTitle: 'Tickets',
      totalText: 'Total of',
      breakReasonText: 'ticket',
      breakReasonsText: 'tickets',
      shareLink: 'Share',
      shareText: 'Share link with customer to create a new ticket.',
      buttonText: 'New ticket',
      linkText: 'Share Link - Helpdesk',
      clientText:
        'Choose for which customer the link to open request tickets will be generated.',
      clientTextTooltip:
        'Using this link, the customer will be able to register requests in the system on their own.',
      buttonLink: 'Generate Link',
    },
  },
  es: {
    HelpDesk: {
      cardTitle: 'Tickets',
      totalText: 'Total de',
      breakReasonText: 'ticket',
      breakReasonsText: 'tickets',
      shareLink: 'Comparta',
      shareText:
        'Comparta el enlace con el cliente para crear un nuevo ticket.',
      buttonText: 'Nuevo ticket',
      linkText: 'Compartir Enlace - Helpdesk',
      clientText:
        'Elija para qué cliente se generará el enlace para abrir tickets de solicitud.',
      clientTextTooltip:
        'A través de este enlace, el cliente podrá registrar sus solicitudes en el sistema por su cuenta.',
      buttonLink: 'Generar enlace',
    },
  },
}
