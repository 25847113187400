export default {
  pt: {
    DashboardMain: {
      tasksTitle: 'Dashboard',
      contributorsTitle: 'Colaboradores',
      mapWithTasksTitle: 'Mapa com tarefas',
      mapWithContributorsTitle: 'Mapa com colaboradores',
      dashboardTitle: 'Dashboard',
    },
  },
  en: {
    DashboardMain: {
      tasksTitle: 'Dashboard',
      contributorsTitle: 'Collaborators',
      mapWithTasksTitle: 'Map with tasks',
      mapWithContributorsTitle: 'Map with collaborators',
      dashboardTitle: 'Dashboard',
    },
  },
  es: {
    DashboardMain: {
      tasksTitle: 'Dashboard',
      contributorsTitle: 'Colaboradores',
      mapWithTasksTitle: 'Mapa con tareas',
      mapWithContributorsTitle: 'Mapa con colaboradores',
      dashboardTitle: 'Panel',
    },
  },
}
