export default {
  pt: {
    TasksTabsDetailsLink: {
      messageSuccess: 'Link copiado para a área de transferência!',
      tooltipTitle: 'Compartilhar tarefa',
      modalTitle: 'Acompanhar Tarefa',
      whatsapp: 'WhatsApp',
      telegram: 'Telegram',
      email: 'Email',
      copyText: 'Copiar Link',
      noLinkText: 'Nenhum link gerado',
    },
  },
  en: {
    TasksTabsDetailsLink: {
      messageSuccess: 'Link copied to clipboard!',
      tooltipTitle: 'Share task',
      modalTitle: 'Track Task',
      whatsapp: 'WhatsApp',
      telegram: 'Telegram',
      email: 'E-mail',
      copyText: 'Copy Link',
      noLinkText: 'No links generated',
    },
  },
  es: {
    TasksTabsDetailsLink: {
      messageSuccess: '¡Enlace copiado al portapapeles!',
      tooltipTitle: 'Compartir tarea',
      modalTitle: 'Seguimiento de tarea',
      whatsapp: 'WhatsApp',
      telegram: 'Telegram',
      email: 'Correo electrónico',
      copyText: 'Copiar enlace',
      noLinkText: 'No se generan enlaces',
    },
  },
}
